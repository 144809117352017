import ForumIcon from "@material-ui/icons/Forum";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import { PATH_APP, PATH_PAGE } from "~/routes/paths";

// export const BASE_URL = 'http://localhost:8000/';//http://35.154.36.117:8000/
// export const BASE_URL = "http://prod.financebox.in/";
// export const BASE_URL = 'http://127.0.0.1:8000/';
// export const BASE_URL = 'http://dev1.financebox.in:8000/';
// export const BASE_URL = 'http://dev.financebox.in:8000/';

// export const BASE_URL = 'http://dev-new.financebox.in:8000/';
export const BASE_URL = "https://prod.financebox.in/";

export const headers = {
  "Content-Type": "text/plain"
};

export const NavItems = [
  {
    href: "/",
    title: "Home"
  },
  {
    href: "/services",
    title: "Services"
  },
  {
    href: "/about-us",
    title: "About Us"
  },
  {
    href: "/contact-us",
    title: "Contact Us"
  },
  {
    href: "/app/services",
    title: "Services"
  }
];

export const ProfileItems = [
  {
    href: "/register",
    title: "Register"
  },
  {
    href: "/login",
    title: "Login"
  }
];

export const SibebarItems = [
  {
    href: "/app/services",
    icon: HomeRoundedIcon,
    title: "Home"
  },
  {
    href: "/app/filling-status",
    icon: HomeRoundedIcon,
    title: "Statutory Fillings Status"
  },
  {
    href: "/app/track-your-services",
    icon: MyLocationIcon,
    title: "Track your Service"
  },
  {
    href: "/app/my-investments",
    icon: HomeRoundedIcon,
    title: "My Investments"
  },
  {
    href: "/app/my-insurance",
    icon: HomeRoundedIcon,
    title: "My Insurance"
  },
  {
    href: "/app/queries",
    icon: ForumIcon,
    title: "Post you free query"
  }
];

export const otherServices = {
  accountingSales: {
    title: "Accounting - Sales",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingPurchases: {
    title: "Accounting - Purchases",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingBank: {
    title: "Accounting - Bank",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingCashOrPettyCash: {
    title: "Accounting - Cash or Petty cash",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingJournal: {
    title: "Accounting - Journal",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingSuspenseClearence: {
    title: "Accounting - Suspense Clearence",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingOthers: {
    title: "Accounting - Others",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  rocOthers: {
    title: "ROC - Others",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  accountingBRS: {
    title: "Accounting - BRS",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnGSTR1: {
    title: "GST Returns - GSTR1",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnGSTR3B: {
    title: "GST Returns - GSTR3B",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnGSTR2B: {
    title: "GST Returns - GSTR2B",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnGSTR2A: {
    title: "GST Returns - GSTR2A",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnGSTReconciliation: {
    title: "GST Returns - GST Reconciliation",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnChallan: {
    title: "GST Returns - Challan",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  },
  gstReturnPayment: {
    title: "GST Returns - Payment",
    icon: "/static2/ServiceIcons/Business.svg",
    href: "",
    endNode: true,
    description: ""
  }
};

export const services = {
  individualServices: {
    title: "Individual Services",
    href: PATH_APP.app2.services.IndividualServices,
    icon: "/static2/ServiceIcons/Individual.svg",
    description: "This is Individual Services",
    heading: "Individual Services"
  },
  businessServices: {
    title: "Business Services",
    href: PATH_APP.app2.services.BusinessServices,
    icon: "/static2/ServiceIcons/Business.svg",
    description: "This is Business Services",
    heading: "Business Services"
  },
  others: {
    title: "Others",
    href: "",
    icon: "/static2/ServiceIcons/Business.svg",
    description: "This is Business Services",
    heading: "Others"
  },
  GSTRegistrationIndividual: {
    title: "GST Registration - Individual",
    icon: "/static2/ServiceIcons/GSTServices/Individual.svg",
    href: PATH_APP.app2.GSTServices.registrationIndividual,
    endNode: true,
    description: "",
    tags: ["Registration: Individual", "GST", "Business"]
  },
  GSTRegistrationCompany: {
    title: "GST Registration - Company",
    icon: "/static2/ServiceIcons/GSTServices/Company.svg",
    href: PATH_APP.app2.GSTServices.registrationcompany,
    endNode: true,
    description: "",
    tags: ["Registration: Company", "GST", "Business"]
  },
  salaryHPCPOI: {
    title: "Salary + House Property + Capital gain + Other Income",
    icon: "/static2/ServiceIcons/individual/income tax/Salary+hp+Cg.svg",
    href:
      PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
        .SalaryHousePropertyCapitalGainOtherIncome,
    endNode: true,
    description: "",
    tags: ["Capital Gains", "IT Returns", "Individual"]
  },
  salaryHPOI: {
    title: "Salary + House Property + Other Income",
    icon:
      "/static2/ServiceIcons/individual/income tax/Salary income+ House property.svg",
    href:
      PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
        .SalaryHousePropertyOtherIncome,
    endNode: true,
    description: "",
    tags: ["Other Income", "IT Returns", "Individual"]
  },
  Form26QB: {
    title: "Form 26QB",
    icon:
      "/static2/ServiceIcons/TDSServices/TDS on sale of immovable property.svg",
    href: PATH_APP.app2.TDSOnSaleOfProperty.Form26QB,
    endNode: true,
    description: ""
  },
  DraftingOfDocuments: {
    title: "Drafting Of Documents",
    icon:
      "/static2/ServiceIcons/individual/Financial and legal services/Drafting of documents.svg",
    href: PATH_APP.app2.FinancialAndLegalAdvisory.DraftingOfDocuments,
    endNode: true,
    description: ""
  },
  LegalReview: {
    title: "Legal Review",
    icon:
      "/static2/ServiceIcons/individual/Financial and legal services/Legal review.svg",
    href: PATH_APP.app2.FinancialAndLegalAdvisory.LegalReview,
    endNode: true,
    description: ""
  },
  CoronavirusInsurance: {
    title: "Coronavirus Insurance",
    icon:
      "/static2/ServiceIcons/individual/Insurance/Corono virus insurance.svg",
    href: PATH_APP.app2.InsuranceServices.CoronavirusInsurance,
    endNode: true,
    description: ""
  },
  HealthInsurance: {
    title: "Health Insurance",
    icon: "/static2/ServiceIcons/individual/Insurance/Health Insurance.svg",
    href: PATH_APP.app2.InsuranceServices.HealthInsurance,
    endNode: true,
    description: ""
  },
  CommercialVehicle: {
    title: "Commercial Insurance",
    icon: "",
    href: PATH_APP.app2.InsuranceServices.CommercialVehicle,
    endNode: true,
    description: ""
  },
  CarInsurance: {
    title: "Car Insurance",
    icon: "/static2/ServiceIcons/individual/Insurance/car insurance.svg",
    href: PATH_APP.app2.InsuranceServices.CarInsurance,
    endNode: true,
    description: ""
  },
  TwoWheelerInsurance: {
    title: "Two Wheeler Insurance",
    icon:
      "/static2/ServiceIcons/individual/Insurance/Two wheeler Insurance.svg",
    href: PATH_APP.app2.InsuranceServices.TwoWheelerInsurance,
    endNode: true,
    description: ""
  },
  HomeLoan: {
    title: "Home Loan",
    icon: "/static2/ServiceIcons/Loans/Home loans.svg",
    href: PATH_APP.app2.LoanServices.HomeLoan,
    endNode: true,
    description: ""
  },
  TermLoan: {
    title: "Term Loan",
    icon: "/static2/ServiceIcons/Loans/Term  Loans.svg",
    endNode: true,
    href: PATH_APP.app2.LoanServices.TermLoan,
    description: ""
  },
  VehicleLoans: {
    title: "Vehicle Loans",
    icon: "/static2/ServiceIcons/Loans/Vehicle Loan.svg",
    endNode: true,
    href: PATH_APP.app2.LoanServices.VehicleLoan,
    description: ""
  },
  WorkingCapitolLoan: {
    title: "Working Capitol Loans",
    icon: "/static2/ServiceIcons/Loans/Working Capital Loans.svg",
    endNode: true,
    href: PATH_APP.app2.LoanServices.WorkingCapitolLoan,
    description: ""
  },
  PrivateLimitedCompany: {
    title: "Private Limited Company Incorporation",
    icon:
      "/static2/ServiceIcons/Business Incorporations/COMPANY/Private Limited Co.svg",
    href:
      PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
        .PvtLtdCompanyIncorporation,
    endNode: true,
    description: ""
  },
  // PublicLimitedCompany: {
  //     title: 'Public Limited Company',
  //     icon: '/static2/ServiceIcons/Business Incorporations/Company/Public Limited Co.svg',
  //     href: PATH_PAGE.comingSoon,
  //     description: '',
  // },
  OnePersonCompany: {
    title: "OPC Incorporation",
    icon:
      "/static2/ServiceIcons/Business Incorporations/COMPANY/One Person Co.svg",
    href:
      PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
        .OPCIncorporation,
    endNode: true,
    description: ""
  },
  LimitedLiabilityPatnership: {
    title: "Limited Liability Patnership",
    icon: "/static2/ServiceIcons/Business Incorporations/LLP.svg",
    href: PATH_APP.app2.BusinessIncorporations.LLPIncorporation,
    endNode: true,
    description: ""
  },
  TrustSociety: {
    title: "Trust / Society/ Sec 8 Company",
    icon: "/static2/ServiceIcons/Business Incorporations/Section 8.svg",
    href: PATH_APP.app2.BusinessIncorporations.Sec8CompanyIncorporation,
    endNode: true,
    description: ""
  },
  PatnershipFirm: {
    title: "Partnership Firm",
    icon: "/static2/ServiceIcons/Business Incorporations/Partnership.svg",
    href: PATH_APP.app2.BusinessIncorporations.PatnershipFirm,
    endNode: true,
    description: ""
  },
  StartUpAccounting: {
    title: "Start Up Accounting",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  GrowthAccounting: {
    title: "Growth Accounting",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  DedicatedAccounting: {
    title: "Dedicated Accounting",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  ReviewAccounting: {
    title: "Review Accounting",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  AccountsReceivableManagement: {
    title: "Accounts Receivable Management",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  EWayBillGeneration: {
    title: "EWay Bill Generation",
    icon: "",
    href: PATH_APP.app2.GSTServices.EwayBillGeneration,
    endNode: true,
    description: ""
  },
  EWayBillRegistration: {
    title: "EWay Bill Registration",
    icon: "",
    href: PATH_APP.app2.GSTServices.EwayBillRegistration,
    endNode: true,
    description: ""
  },
  GSTCancellation: {
    title: "GST Cancellation",
    icon: "/static2/ServiceIcons/GSTServices/Gst Cancellation.svg",
    href: PATH_APP.app2.GSTServices.GSTCancellation,
    endNode: true,
    description: ""
  },
  GSTRevocation: {
    title: "GST Revocation",
    icon: "/static2/ServiceIcons/GSTServices/GST revocation.svg",
    href: PATH_APP.app2.GSTServices.GSTRevocation,
    endNode: true,
    description: ""
  },
  GSTReturns: {
    title: "GST Returns",
    icon: "/static2/ServiceIcons/GSTServices/gst returns.svg",
    href: PATH_APP.app2.GSTServices.GSTReturns,
    endNode: true,
    description: "",
    tags: ["GST Returns", "Business"]
  },
  GSTR9: {
    title: "GSTR9 (Annual Filings)",
    icon: "/static2/ServiceIcons/GSTServices/Gstr- 9.svg",
    href: PATH_APP.app2.GSTServices.GSTR9AnuualReturn,
    endNode: true,
    description: ""
  },
  LUTFilings: {
    title: "LUT Filings",
    icon: "/static2/ServiceIcons/GSTServices/LUT Filings.svg",
    href: PATH_APP.app2.GSTServices.LUTFilings,
    endNode: true,
    description: ""
  },
  AmendmentInGSTRegistration: {
    title: "Amendment In GST Registration",
    icon: "/static2/ServiceIcons/GSTServices/Amendment in Gst Registration.svg",
    href: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
    endNode: true,
    description: ""
  },
  GSTNotice: {
    title: "GST Notice",
    icon: "/static2/ServiceIcons/GSTServices/GstNotice.svg",
    href: PATH_APP.app2.GSTServices.GSTNotice,
    endNode: true,
    description: ""
  },
  TDSFilingsForSalaries: {
    title: "TDS Filings For Salaries (Form 24Q)",
    icon: "/static2/ServiceIcons/TDSServices/Form-24Q.svg",
    href: PATH_APP.app2.TDSServices.Form24Q,
    endNode: true,
    description: ""
  },
  TDSForPaymentsOtherThanSalaries: {
    title: "TDS For Payments Other Than Salaries (Form 26Q)",
    icon: "/static2/ServiceIcons/TDSServices/Form-26Q.svg",
    href: PATH_APP.app2.TDSServices.Form26Q,
    endNode: true,
    description: ""
  },
  TDSOnSaleOfImmovableProperty: {
    title: "TDS on sale of Immovable Property (Form 26QB)",
    icon:
      "/static2/ServiceIcons/TDSServices/TDS on sale of immovable property.svg",
    href: PATH_APP.app2.TDSServices.Form26QB,
    endNode: true,
    description: ""
  },
  TDSForNonResidents: {
    title: "TDS For Non Residents (Form 27Q)",
    icon: "/static2/ServiceIcons/TDSServices/Non-Resident.svg",
    href: PATH_APP.app2.TDSServices.Form27Q,
    endNode: true,
    description: ""
  },
  StatementForTaxCollectionAtSource: {
    title: "Statement For Tax Collection At Source (Form 27EQ)",
    icon: "/static2/ServiceIcons/TDSServices/TCS.svg",
    href: PATH_APP.app2.TDSServices.Form27EQ,
    endNode: true,
    description: ""
  },
  IncomeFromBusinessProfession: {
    title: "Individual/Business Having Income From Business/Profession",
    icon: "/static2/ServiceIcons/IncomeTaxReturns/Income Tax Return-3.svg",
    href: PATH_APP.app2.IncomeTaxReturnsBusiness.IncomeFromBusinessProfession,
    endNode: true,
    description: "",
    tags: ["Business/Profession", "IT Returns", "Business"]
  },
  PatnershipLLPReturns: {
    title: "Patnership/LLP Income Tax Returns",
    icon: "/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg",
    href: PATH_APP.app2.IncomeTaxReturnsBusiness.PatnershipLLPReturns,
    endNode: true,
    description: ""
  },
  CompanyIncomeTaxReturns: {
    title: "Company Income Tax Returns",
    icon: "/static2/ServiceIcons/IncomeTaxReturns/Company.svg",
    href: PATH_APP.app2.IncomeTaxReturnsBusiness.CompanyReturns,
    endNode: true,
    description: ""
  },
  Society: {
    title: "Society Income Tax Returns",
    icon: "/static2/ServiceIcons/IncomeTaxReturns/Others.svg",
    href: PATH_APP.app2.IncomeTaxReturnsBusiness.SocietyReturns,
    endNode: true,
    description: ""
  },
  Trust: {
    title: "Trust Income Tax Returns",
    icon: "/static2/ServiceIcons/IncomeTaxReturns/Others.svg",
    href: PATH_APP.app2.IncomeTaxReturnsBusiness.TrustReturns,
    endNode: true,
    description: ""
  },
  AOC4: {
    title: "AOC-4",
    icon: "/static2/ServiceIcons/ROCServices/AOC-4.svg",
    href: PATH_APP.app2.ROCCompliances.AOC4,
    endNode: true,
    description: ""
  },
  DIR3KYC: {
    title: "DIR3KYC",
    icon: "/static2/ServiceIcons/ROCServices/Dir-3 KYC.svg",
    href: PATH_APP.app2.ROCCompliances.DIR3KYC,
    endNode: true,
    description: ""
  },
  MGT7: {
    title: "MGT-7",
    icon: "/static2/ServiceIcons/ROCServices/MGT-7.svg",
    href: PATH_APP.app2.ROCCompliances.MGT7,
    endNode: true,
    description: ""
  },
  AuditorAppointment: {
    title: "Auditor Appointment",
    icon: "/static2/ServiceIcons/ROCServices/Auditor appointment.svg",
    href: PATH_APP.app2.ROCCompliances.AuditorAppointment,
    endNode: true,
    description: ""
  },
  AuditorRemoval: {
    title: "Auditor Removal",
    icon: "/static2/ServiceIcons/ROCServices/Auditor removal.svg",
    href: PATH_APP.app2.ROCCompliances.AuditorRemoval,
    endNode: true,
    description: ""
  },
  ChangeInAuthorizedShareCapitol: {
    title: "Change In Authorized Share Capitol",
    icon: "/static2/ServiceIcons/ROCServices/change in share capital.svg",
    href: PATH_APP.app2.ROCCompliances.ChangeInAuthorizedShareCapitol,
    endNode: true,
    description: ""
  },
  ChangeInObjects: {
    title: "Change In Objects",
    icon: "/static2/ServiceIcons/ROCServices/change in objects.svg",
    href: PATH_APP.app2.ROCCompliances.ChangeInObjects,
    endNode: true,
    description: ""
  },
  CommencementOfBusiness: {
    title: "Commencement Of Business",
    icon: "/static2/ServiceIcons/ROCServices/Commencement of business.svg",
    href: PATH_APP.app2.ROCCompliances.CommencementOfBusiness,
    endNode: true,
    description: ""
  },
  DPT3: {
    title: "DPT-3",
    icon: "/static2/ServiceIcons/ROCServices/DPT-3.svg",
    href: PATH_APP.app2.ROCCompliances.DPT3,
    endNode: true,
    description: ""
  },
  Form11: {
    title: "Form 11",
    icon: "/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 8.svg",
    href: PATH_APP.app2.ROCCompliances.Form11,
    endNode: true,
    description: ""
  },
  Form8: {
    title: "Form 8",
    icon: "/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 11.svg",
    href: PATH_APP.app2.ROCCompliances.Form8,
    endNode: true,
    description: ""
  },
  ESIRegistration: {
    title: "ESI Registration",
    icon: "/static2/ServiceIcons/PayrollServices/Esi Registration.svg",
    href: PATH_APP.app2.PayrollCompliances.ESIRegistration,
    endNode: true,
    description: ""
  },
  ESIReturns: {
    title: "ESI Returns Filing",
    icon: "/static2/ServiceIcons/PayrollServices/ESI returns.svg",
    href: PATH_APP.app2.PayrollCompliances.ESIReturnFiling,
    endNode: true,
    description: ""
  },
  PFRegistration: {
    title: "PF Registration",
    icon: "/static2/ServiceIcons/PayrollServices/PF Reg`n.svg",
    href: PATH_APP.app2.PayrollCompliances.PFRegistration,
    endNode: true,
    description: ""
  },
  PFReturnsFilings: {
    title: "PF Returns Filings",
    icon: "/static2/ServiceIcons/PayrollServices/PF Filing.svg",
    endNode: true,
    href: PATH_APP.app2.PayrollCompliances.PFFiling,
    description: ""
  },
  PTRegistrations: {
    title: "PT Registrations",
    icon: "/static2/ServiceIcons/PayrollServices/PT Reg`n.svg",
    href: PATH_APP.app2.PayrollCompliances.PTRegistration,
    endNode: true,
    description: ""
  },
  PTReturnsFilings: {
    title: "PT Returns Filings",
    icon: "/static2/ServiceIcons/PayrollServices/PT Return filing.svg",
    href: PATH_APP.app2.PayrollCompliances.PTReturnFiling,
    endNode: true,
    description: ""
  },
  PayrollProcessing: {
    title: "Payroll Processing",
    icon: "/static2/ServiceIcons/PayrollServices/Payroll Processing.svg",
    href: PATH_APP.app2.PayrollCompliances.PayrollProcessing,
    endNode: true,
    description: ""
  },
  HomeLoan: {
    title: "Home Loan",
    icon: "/static2/ServiceIcons/Loans/Home loans.svg",
    href: PATH_APP.app2.Loans.HomeLoan,
    endNode: true,
    description: ""
  },
  TermLoan: {
    title: "Term Loan",
    icon: "/static2/ServiceIcons/Loans/Term  Loans.svg",
    endNode: true,
    href: PATH_APP.app2.Loans.TermLoan,
    description: ""
  },
  VehicleLoans: {
    title: "Vehicle Loans",
    icon: "/static2/ServiceIcons/Loans/Vehicle Loan.svg",
    endNode: true,
    href: PATH_APP.app2.Loans.VehicleLoan,
    description: ""
  },
  WorkingCapitolLoan: {
    title: "Working Capitol Loans",
    icon: "/static2/ServiceIcons/Loans/Working Capital Loans.svg",
    endNode: true,
    href: PATH_APP.app2.Loans.WorkingCapitolLoan,
    description: ""
  },
  DraftingOfDocuments: {
    title: "Drafting Of Documents",
    icon:
      "/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Drafting of documents.svg",
    href: PATH_APP.app2.FinancialAdviosryAndLegalServices.DraftingOfDocuments,
    endNode: true,
    description: ""
  },
  LegalReview: {
    title: "Legal Review",
    icon:
      "/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Legal  Review.svg",
    href: PATH_APP.app2.FinancialAdviosryAndLegalServices.LegalReview,
    endNode: true,
    description: ""
  },
  AggressiveFunds: {
    title: "Aggressive Funds",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  BalancedGrowthFund: {
    title: "Balanced Growth Fund",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  TaxSavingsFund: {
    title: "Tax Savings Fund",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  LiquidFunds: {
    title: "Liquid Funds",
    icon: "",
    href: PATH_PAGE.comingSoon,
    endNode: true,
    description: ""
  },
  CoronavirusInsurance: {
    title: "Coronavirus Insurance",
    icon: "",
    href: PATH_APP.app2.InsuranceAdvisory.CoronavirusInsurance,
    endNode: true,
    description: ""
  },
  HealthInsurance: {
    title: "Health Insurance",
    icon: "",
    href: PATH_APP.app2.InsuranceAdvisory.HealthInsurance,
    endNode: true,
    description: ""
  },
  CommercialVehicle: {
    title: "Commercial Insurance",
    icon: "",
    href: PATH_APP.app2.InsuranceAdvisory.CommercialVehicle,
    endNode: true,
    description: ""
  },
  CarInsurance: {
    title: "Car Insurance",
    icon: "",
    href: PATH_APP.app2.InsuranceAdvisory.CarInsurance,
    endNode: true,
    description: ""
  },
  TwoWheelerInsurance: {
    title: "Two Wheeler Insurance",
    icon: "",
    href: PATH_APP.app2.InsuranceAdvisory.TwoWheelerInsurance,
    endNode: true,
    description: ""
  },
  DigitalSignatures: {
    title: "Digital Signatures",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/Digital Signatures.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.DigitalSignature,
    endNode: true,
    description: ""
  },
  FSSAIFoodLicenseRegistrations: {
    title: "Food License Registrations",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/Food License.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.FoodLicenseRegistration,
    endNode: true,
    description: ""
  },
  Form15CA15CB: {
    title: "Form 15CA and 15CB",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/15CA and 15CB.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.Form15CAAnd15CB,
    endNode: true,
    description: ""
  },
  IECRegistration: {
    title: "IEC Registration",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/IEC Registration.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.IECRegistration,
    endNode: true,
    description: ""
  },
  MSMERegistration: {
    title: "MSME Registration",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/MSME Registration.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.MSMERegistration,
    endNode: true,
    description: ""
  },
  PANRegistration: {
    title: "PAN Registration",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/Pan Card.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.PANRegistration,
    endNode: true,
    description: ""
  },
  ShopsAndEstablishmentActRegistrations: {
    title: "Shops And Establishment Act Registrations",
    icon:
      "/static2/ServiceIcons/OtherGovtRegistrations/Shops & Establishment License.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.ShopsAndEshtablishments,
    endNode: true,
    description: ""
  },
  TrademarkRegistration: {
    title: "Trademark Registration",
    icon: "/static2/ServiceIcons/OtherGovtRegistrations/Trademark.svg",
    href: PATH_APP.app2.OtherGovtRegistrations.TrademarkRegistration,
    endNode: true,
    description: ""
  },
  ...otherServices
};

export const Firm_ID = 1;
