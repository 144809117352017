import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
// import Search from './Search';
import Account from "./Account";
import Header from "./Header";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify-icons/eva/menu-2-fill";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
  ButtonGroup,
  ClickAwayListener,
  Menu,
  Button,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem
} from "@material-ui/core";
import { userContext } from "~/ContextProvider";
import { BASE_URL } from "~/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getBusiness } from "~/redux/slices/business";
import { useSelector } from "react-redux";
import Notifications from "./Notifications/index";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92; previous
const APPBAR_DESKTOP = 70;

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    backdropFilter: "blur(8px)",
    color: "#000",
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 5)
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: APPBAR_DESKTOP
    }
  },
  btnMenu: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary
  }
}));

// ----------------------------------------------------------------------

TopBar.propTypes = {
  onOpenNav: PropTypes.func,
  className: PropTypes.string
};

function TopBar({ onOpenNav, className }) {
  const classes = useStyles();
  const [user, setUser] = useContext(userContext);
  const { userRole } =
    user || JSON.parse(localStorage.getItem("user_details")) || {};
  const userLoginDetails = JSON.parse(localStorage.getItem("user_details"));
  let businessId = useSelector(state => state.business);
  businessId = businessId?.business?.business?.id || null;

  const { userDetails } = useSelector(state => state.loginUserDetails);

  const [allBusiness, setAllBusiness] = useState([]);

  // const [selectedIndex, setSelectedIndex] = React.useState(0);

  // const handleClickListItem = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const dispatch = useDispatch();

  // const handleMenuItemClick = (event, index) => {
  //   setSelectedIndex(index);
  //   dispatch(getBusiness({ business: allBusiness[index] }));
  //   setAnchorEl(null);
  // };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${allBusiness[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    dispatch(getBusiness({ business: allBusiness[index] }));
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (userRole == 3 && userDetails.serviceType == "business") {
      try {
        axios
          .get(
            BASE_URL +
              `user_management/business?clientId=${userLoginDetails?.userId}`,
            {
              // .get(BASE_URL + 'common/business', {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`
              }
            }
          )
          .then(res => {
            setAllBusiness(res.data);
            dispatch(getBusiness({ business: res.data[0] }));
          });
      } catch (error) {
        console.log("err", error);
      }
    }
  }, [userDetails.serviceType]);

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        {/* Have to set the header area like home layout */}
        {/* <RouterLink to="/">
          <Logo />
        </RouterLink> */}

        <Hidden lgUp>
          <IconButton onClick={onOpenNav} className={classes.btnMenu}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>
        {userRole === 3 && <Header />}
        {/* <Search /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > *:not(:first-of-type)": {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3
              }
            }
          }}
        >
          {/* {userRole === 1 &&
            <Notifications />
          } */}
          {/* <Languages />
          // <Notifications /> */}
          {/* <Notifications /> */}
          {/* <DarkMode /> */}
          {userRole == 3 &&
            userDetails.serviceType == "business" &&
            allBusiness.length > 0 && (
              <Box>
                <ButtonGroup
                  variant="outlined"
                  // style={{ width: '250px' }}
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button onClick={handleClick}>
                    Business : {allBusiness[selectedIndex]?.nameOfBusiness}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  // style={{ width: '250px' }}
                  role={undefined}
                  placement={"bottom-end"}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {allBusiness.map((option, index) => (
                              <MenuItem
                                key={index}
                                selected={index === selectedIndex}
                                onClick={event =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option.nameOfBusiness}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {/* <Button
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? 'true' : undefined}
                  style={{ marginRight: '10px' }}
                  variant="outlined"
                  onClick={handleClick}
                >
                  Business : {allBusiness[selectedIndex]?.nameOfBusiness}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  {allBusiness.map((row, idx) => {
                    return (
                      <MenuItem
                        selected={idx === selectedIndex}
                        onClick={event => handleMenuItemClick(event, idx)}
                      >
                        {row.nameOfBusiness}
                      </MenuItem>
                    );
                  })}
                </Menu> */}
              </Box>
            )}
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
