import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  selectedEmployees: [],
  error: null,
};

const slice = createSlice({
  name: 'salaryRevisionEmployees',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getSelectedEmployeesSuccess(state, action) {
      state.isLoading = false;
      state.selectedEmployees = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addSelectedEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.selectedEmployees.push(action.payload);
    },
    updateSelectedEmployeeSuccess(state, action) {
      state.isLoading = false;
      const updatedEmployee = action.payload;
      state.selectedEmployees = state.selectedEmployees.map(employee =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee
      );
    },
    deleteSelectedEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.selectedEmployees = state.selectedEmployees.filter(employee =>
        employee.id !== action.payload
      );
    },
  },
});

export const {
  startLoading,
  getSelectedEmployeesSuccess,
  hasError,
  addSelectedEmployeeSuccess,
  updateSelectedEmployeeSuccess,
  deleteSelectedEmployeeSuccess,
} = slice.actions;

export const fetchSelectedEmployees = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Assume you have a function to fetch the selected employees from local storage or an API
    const selectedEmployees = []; // Replace with actual data fetching logic
    dispatch(getSelectedEmployeesSuccess(selectedEmployees));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const addSelectedEmployee = (employee) => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Simulate adding employee logic, e.g., saving to local storage or an API call
    dispatch(addSelectedEmployeeSuccess(employee));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const updateSelectedEmployee = (employee) => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Simulate updating employee logic, e.g., saving to local storage or an API call
    dispatch(updateSelectedEmployeeSuccess(employee));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const deleteSelectedEmployee = (employeeId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Simulate deleting employee logic, e.g., removing from local storage or an API call
    dispatch(deleteSelectedEmployeeSuccess(employeeId));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export default slice.reducer;
