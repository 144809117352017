import NavBar from "./NavBar";
import TopBar from "./TopBar";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { tokenContext } from "~/ContextProvider";
import HomeTopBar from "./../HomeLayout/TopBar";
import { Container } from "@material-ui/core";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
const APP_BAR_DESKTOP = 50;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
    width: "100%"
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto"
  },
  main: {
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    // anand
    // margin: '-20px 0 0 0px',
    justifyContent: "center",
    // anand

    paddingTop: APP_BAR_DESKTOP,
    // paddingTop: APP_BAR_MOBILE + 40,
    paddingBottom: theme.spacing(10),
    // [theme.breakpoints.up('lg')]: {
    //   paddingTop: APP_BAR_DESKTOP + 10,
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2),
    //   minWidth:1450
    // },
    // [theme.breakpoints.down('lg')]: {
    //   paddingTop: APP_BAR_DESKTOP + 10,
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2),
    //   // minWidth:'100%'
    // }

    "@media only screen and (min-width: 1500px)": {
      minWidth: 1200
    },
    "@media only screen and (min-width: 1700px)": {
      minWidth: 1550
    }
  }
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node
};

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);
  const [token] = useContext(tokenContext);
  const isLoggedIn = Boolean(token || localStorage.getItem("token") || false);
  return (
    <div className={classes.root}>
      {isLoggedIn ? (
        <div>
          <TopBar onOpenNav={() => setOpenNav(true)} />
          <NavBar onCloseNav={() => setOpenNav(false)} isOpenNav={openNav} />
        </div>
      ) : (
        <HomeTopBar />
      )}
      {/* <Sidebar onMobileClose={() => setOpenNav(false)} openMobile={openNav} /> */}
      {/* <div className={classes.wrapper}>
          <div className={classes.contentContainer}> */}
      <Container className={classes.main}>
        {children}
        {/* </div>
          </div> */}
      </Container>
    </div>
  );
}

export default DashboardLayout;
