import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import darkModeReducer from './slices/dark-mode';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import statusReducer from './slices/statusslice';
import entityReducer from './slices/entitytypeslice';
import analysisReducer from './slices/analysis';
import complianceReducer from './slices/compliance';
import masterlandingReducer from './slices/masterlanding';
import phaseReducer from './slices/phaseslice';
import productReducer from './slices/product';
import userReducer from './slices/user';
import notificationsReducer from './slices/notifications';
import clientReducer from './slices/client';
import privilegesReducer from './slices/privileges';
import businessReducer from './slices/business';
import loginUserReducer from './slices/loginUserDetails';
import complianceManagerReducer from './slices/complianceManager';
import landingpageslice from './slices/landingpageslice';
import industryReducer from './slices/industry';
import sectorReducer from './slices/sector'
import templateReducer from './slices/templates'
// import areaReducer from './slices/area'
// import entityReducer from './slices/entitytypeslice'
import projectReducer from './slices/projects'
import editProjectReducer from './slices/editProjectSlice'
import stepReducer from './slices/masterstepslice';
// import complianceReducer from './slices/mastercompliance';
import subareaReducer from './slices/mastersubareaslice';
import areaReducer from './slices/masterareaslice';
import accountReducer from './slices/account'

import basicinfoReducer from './slices/basicinfoslice'
import taskAssignmentReducer from './slices/taskassignmentslice';
import trialBalanceReducer from './slices/trialBalanceslice';
import dicReducer from './slices/dic'
import employeeReducer from './slices/payroll/employee'
import salaryRevisionSelectedEmployeesReducer from './slices/payroll/payrollInput/salaryRevisionSuggestion'
import departmentReducer from './slices/departments'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: ['theme']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  blacklist: ['isLoading', 'error', 'products', 'product', 'filters']
};

const clientPersistConfig = {
  key: 'client',
  storage: storage,
  keyPrefix: 'redux-'
};

const privilegesPersistConfig = {
  key: 'privileges',
  storage: storage,
  keyPrefix: 'redux-'
};
const landingpagePersistConfig = {
  key: 'landingpage',
  storage: storage,
  // keyPrefix: 'redux-'
};

const businessPersistConfig = {
  key: 'business',
  storage: storage,
  keyPrefix: 'redux-'
};

const loginUserDetailsPersistConfig = {
  key: 'loginUserDetails',
  storage: storage,
  keyPrefix: 'redux-'
};

const complianceManagerPersistConfig = {
  key: 'complianceManager',
  storage: storage,
  keyPrefix: 'redux-'
};
const industriesPersistConfig = {
  key: 'industries',
  storage: storage,
  keyPrefix: 'redux-'
};

const sectorsPersistConfig = {
  key: 'sectors',
  storage: storage,
  keyPrefix: 'redux-'
};
const templatePersistConfig = {
  key: 'template',
  storage: storage,
  keyPrefix: 'redux-'
};
const areaPersistConfig = {
  key: 'area',
  storage: storage,
  keyPrefix: 'redux-'
};
const entityPersistConfig = {
  key: 'entitytype',
  storage: storage,
  keyPrefix: 'redux-'
};
const departmentPersistConfig = {
  key: 'department',
  storage: storage,
  keyPrefix: 'redux-'
};
const projectPersistConfig = {
  key: 'project',
  storage: storage,
  keyPrefix: 'redux-'
};
const accountPersistConfig = {
  key: 'account',
  storage: storage,
  keyPrefix: 'redux-'
};


const subAreaPersistConfig = {
  key: 'subarea',
  storage: storage,
  keyPrefix: 'redux-'
};
const dicPersistConfig = {
  key: 'dic',
  storage: storage,
  keyPrefix: 'redux-'
};
const employeePersistConfig = {
  key: 'employee',
  storage: storage,
  keyPrefix: 'redux-'
};
const salaryRevisionSelectedEmployeesPersistConfig = {
  key: 'salaryRevisionSelectedEmployees',
  storage: storage,
  keyPrefix: 'redux-'
};


const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  theme: darkModeReducer,
  calendar: calendarReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  status:statusReducer,
  basicinfo:basicinfoReducer,
  entitytype:entityReducer,
  analysis:analysisReducer,
  phase: phaseReducer,
  compliance:complianceReducer,
  master:masterlandingReducer,
  product: persistReducer(productPersistConfig, productReducer),
  user: userReducer,
  phase: phaseReducer,
  editProject: editProjectReducer,
  compliance: complianceReducer,
  step: stepReducer,
  subarea: persistReducer(subAreaPersistConfig,subareaReducer),
  area: areaReducer,
  taskAssignment: taskAssignmentReducer,
  trialBalance: trialBalanceReducer,
  notifications: notificationsReducer,
  client: persistReducer(clientPersistConfig, clientReducer),
  privileges: persistReducer(privilegesPersistConfig, privilegesReducer),
  business: persistReducer(businessPersistConfig, businessReducer),
  loginUserDetails: persistReducer(
    loginUserDetailsPersistConfig,
    loginUserReducer
  ),
  complianceManager: persistReducer(
    complianceManagerPersistConfig,
    complianceManagerReducer
  ),
  //// Ananad Garikapati
  // landingpagedetails:landingpageslice,
  landingpagedetails:persistReducer(landingpagePersistConfig, landingpageslice), 
  industry : persistReducer(industriesPersistConfig,industryReducer),
  sector : persistReducer(sectorsPersistConfig,sectorReducer),
  template : persistReducer(templatePersistConfig,templateReducer),
  area : persistReducer(areaPersistConfig,areaReducer),
  entitytype : persistReducer(entityPersistConfig,entityReducer),
  department : persistReducer(departmentPersistConfig,departmentReducer),
  project : persistReducer(projectPersistConfig,projectReducer),
  account : persistReducer(accountPersistConfig,accountReducer),
  dic : persistReducer(dicPersistConfig,dicReducer),
  employee : persistReducer(employeePersistConfig,employeeReducer),
  salaryRevisionSelectedEmployees : persistReducer(salaryRevisionSelectedEmployeesPersistConfig,salaryRevisionSelectedEmployeesReducer)
});

export { rootPersistConfig, rootReducer };
