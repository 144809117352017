// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}
const id = localStorage.getItem('taskId');

const ROOTS = {
  auth: '/auth',
  app: '/app',
  docs: '/docs'
};
export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    firmRegister: path(ROOTS.auth, '/firm-register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_HOME = {
  home: '/',
  services: '/services',
  // cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  // purchase: 'https://material-ui.com/store/items/gmail-dashboard/',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  thankYou: '/thank-you',
  // Temporary
  Loans: '/loans',
  ITR: '/itr',
  // Application
  application: '/registration',

  representativeRegistration: '/representative-registration',
  careers: {
    root: '/careers',
    post: '/careers/:id'
  },
  pitchDeck: '/pitch-deck',
  register: '/auth/register',
  firmRegister: '/auth/firm-register',
  login: '/auth/login',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  blog: {
    root: '/blog',
    post: '/blog/post/:title'
  },

  BusinessIncorporations: {
    // PatnershipFirm: path(
    //   ROOTS.app,
    //   '/app/services/business-services/business-incorporation/partnership-firm'
    // ),
    // SoleProprietorshipIncorporation: path(
    //   ROOTS.app,
    //   '/app/services/business-services/business-incorporation/sole-proprietor'
    // ),
    // Sec8CompanyIncorporationView: path(
    //   ROOTS.app,
    //   '/app/services/business-services/business-incorporation/trust-society-company'
    // ),
    // LLPIncorporation: path(
    //   ROOTS.app,
    //   '/app/services/business-services/business-incorporation/llp'
    // ),
    // ProducerCompanyView: path(
    //   ROOTS.app,
    //   '/app/services/business-services/business-incorporation/producer-company'
    // ),
    // NidhiCompanyView: path(
    //   ROOTS.app,
    //   '/app/services/business-services/business-incorporation/nidhi-company'
    // ),
    CompanyIncorporations: {
      PvtLtdCompanyIncorporation: path(ROOTS.app, '/private-limited-company'),
      OPCIncorporation: path(
        ROOTS.app,
        '/app/services/business-services/business-incorporation/company/one-person-company'
      )
    }
  }
};

export const PATH_APP = {
  root: ROOTS.app,
  // Temporary app2 paths defined
  app2: {
    taskReview: path(ROOTS.app, '/task-review'),
    taskManagement: {
      root: path(ROOTS.app, '/task-management'),
      jobManagement: path(ROOTS.app, '/job-management'),
      dashboards: path(ROOTS.app, '/dashboards')
    },
    payrollCompliances : {
      root : path(ROOTS.app,'/payroll-compliances'),
      employeeMaster : {
        root : path(ROOTS.app,'/payroll-compliances/employee-master'),
        employeeAdd : path(ROOTS.app,'/payroll-compliances/employee-master/add'),
        employeeEdit : path(ROOTS.app,'/payroll-compliances/employee-master/:id')
      },
      payrollInput : {
        leavesAndCompOff : path(ROOTS.app,'/payroll-compliances/payroll-inputs/leaves-and-compoff'),
        salaryRevision : path(ROOTS.app,'/payroll-compliances/payroll-inputs/salary-revision'),
        overTime : path(ROOTS.app,'/payroll-compliances/payroll-inputs/over-time'),
        lop : path(ROOTS.app,'/payroll-compliances/payroll-inputs/lop'),
        variablePay : path(ROOTS.app,'/payroll-compliances/payroll-inputs/variablePay'),
        deduction : path(ROOTS.app,'/payroll-compliances/payroll-inputs/deduction'),
        reimbursment : path(ROOTS.app,'/payroll-compliances/payroll-inputs/reimbursment'),
        salaryAdvance : path(ROOTS.app,'/payroll-compliances/payroll-inputs/salary-advance'),
        salaryArrers : path(ROOTS.app,'/payroll-compliances/payroll-inputs/salary-arrears'),
        salaryOnHold : path(ROOTS.app,'/payroll-compliances/payroll-inputs/salary-on-hold'),
        overrideTDS : path(ROOTS.app,'/payroll-compliances/payroll-inputs/override-tds'),
        employeeExistDetails : path(ROOTS.app,'/payroll-compliances/payroll-inputs/employee-exit-details'),
        newJoinee : path(ROOTS.app,'/payroll-compliances/payroll-inputs/new-joinee'),
        salaryRevisionEdit : path(ROOTS.app,'/payroll-compliances/payroll-inputs/salary-revision/edit'),


      },
      attendanceAndLeaveRegister : {
        root : path(ROOTS.app,'/payroll-compliances/attendance-and-leaveregister')
      }
    },

    myTasks: path(ROOTS.app, '/my-tasks'),
    Payments: path(ROOTS.app, '/payments'),

    //INCOMETAX NEW 2024
    IncomeTax: {
      root: path(ROOTS.app, '/income-tax'),
      dashboard: path(ROOTS.app, '/itr-dashboard')
    },
    advancetax: {
      root: path(ROOTS.app, "/advance-tax"),
      
    },

    duediligence: {
      root: path(ROOTS.app, '/due-diligence'),
      basicInfo: path(ROOTS.app, '/due-diligence/basicinfo/:id'),
      basicinfo: path(ROOTS.app, `/due-diligence/basicinfo/${id}`),
      dueDiligencePlan: path(ROOTS.app, '/due-diligence-plan'),
      engagementAndscoping: path(
        ROOTS.app,
        '/due-diligence/engagement-and-scoping'
      ),
      // DDPQueriesAndConclusion: path(ROOTS.app, '/due-diligence/ddp/queries-and-conclusion'),
      queriesAndConclusion: path(
        ROOTS.app,
        '/due-diligence/queries-and-conclusion'
      )
    },
    dic: {
      root: path(ROOTS.app, '/dic')
    },
    InputData: path(ROOTS.app, '/InputData'),
    ComplianceTesting: path(ROOTS.app, '/compliancetesting'),
    trendAnalysis: path(ROOTS.app, '/trend-analysis'),
    analyticsAndSubstantiveTesting: {
      analytics: path(
        ROOTS.app,
        '/due-diligence/analytics-and-substantive-testing/analytics'
      ),
      substantiveTesting: path(
        ROOTS.app,
        '/due-diligence/analytics-and-substantive-testing/substantive-testing'
      )
    },
    //addRepresentatives
    addRepresentatives: path(ROOTS.app, '/add-representatives'),

    documentDrafting: {
      root: path(ROOTS.app, '/document-drafting'),
      requirements: path(ROOTS.app, '/requirements'),
      numberOfDocumentsGenerated: path(
        ROOTS.app,
        '/document-drafting/genrated-documents'
      )
    },

    //Firm Management
    firmManagement: {
      root: path(ROOTS.app, '/firm-management'),
      customisation: path(ROOTS.app, '/firm-management/customisation')
      // addBusiness: path(ROOTS.app, '/my-business/add-business'),
      // editBusiness: path(ROOTS.app, '/my-business/edit-business/:id')
    },

    //My Business
    myBusiness: {
      root: path(ROOTS.app, '/my-business'),
      addBusiness: path(ROOTS.app, '/my-business/add-business'),
      editBusiness: path(ROOTS.app, '/my-business/edit-business/:id')
    },

    services: {
      root: path(ROOTS.app, '/services'),
      //individual services
      IndividualServices: path(ROOTS.app, '/services/individual-services'),
      ReviewTracking: path(ROOTS.app, '/services/review-tracking'),
      IncomeTaxReturns: path(
        ROOTS.app,
        '/services/individual-services/income-tax-returns'
      ),
      TdsOnSaleOfProperty: path(
        ROOTS.app,
        '/services/individual-services/tds-on-sale-of-property'
      ),
      FinancialAndLegalAdvisory: path(
        ROOTS.app,
        '/services/individual-services/financial-legal-services'
      ),
      InsuranceServices: path(
        ROOTS.app,
        '/services/individual-services/insurance-services'
      ),
      LoanServices: path(
        ROOTS.app,
        '/services/individual-services/loan-services'
      ),
      HealthInsuranceIndividual: path(
        ROOTS.app,
        '/services/individual-services/insurance-services/health'
      ),
      VehicleInsuranceIndividual: path(
        ROOTS.app,
        '/services/individual-services/insurance-services/vehicle'
      ),
      SalariedEmployee: path(
        ROOTS.app,
        '/services/individual-services/income-tax-returns/salaried-employee'
      ),

      //business-services
      BusinessServices: path(ROOTS.app, '/services/business-services'),
      BusinessIncorporationandRegistrations: path(
        ROOTS.app,
        '/services/business-services/business-incorporation-and-registration'
      ),
      BusinessIncorporations: path(
        ROOTS.app,
        '/services/business-services/business-incorporation'
      ),
      Company: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/company'
      ),

      MonthEndReviews: path(
        ROOTS.app,
        '/services/business-services/month-end-reviews'
      ),
      MISReport: path(ROOTS.app, '/services/business-services/mis-report'),
      Audit: path(ROOTS.app, '/services/audit'),
      InternalAudit: path(
        ROOTS.app,
        '/services/business-services/internal-audit'
      ),
      CertificationServices: path(
        ROOTS.app,
        '/services/business-services/CertificationServices'
      ),
      SpecialAudits: path(
        ROOTS.app,
        '/services/business-services/SpecialAudits'
      ),
      AgreedUponProcedures: path(
        ROOTS.app,
        '/services/business-services/agreed-upon-procedures'
      ),
      SystemsAudit: path(
        ROOTS.app,
        '/services/business-services/systems-audit'
      ),
      ManagementAudit: path(
        ROOTS.app,
        '/services/business-services/management-audit'
      ),
      TaxAudit: path(ROOTS.app, '/services/business-services/tax-audit'),
      StatutoryAudit: path(
        ROOTS.app,
        '/services/business-services/statutory-audit'
      ),
      PreparingAndFilingofStatutoryPaymentsandReturns: path(
        ROOTS.app,
        '/services/business-services/PreparingAndFilingofStatutoryPaymentsandReturns'
      ),
      PayrollMaintanance: path(
        ROOTS.app,
        '/services/business-services/payroll-maintanance'
      ),
      BookKeeping: path(ROOTS.app, '/services/business-services/bookkeeping'),
      GSTServices: path(ROOTS.app, '/services/business-services/gst-services'),
      PaymentsinTDS: path(ROOTS.app, '/services/business-services/Payments'),
      TDSServices: path(ROOTS.app, '/services/business-services/tds-services'),
      TDSTCS: path(ROOTS.app, '/services/business-services/tdstcs'),
      IncomeTaxReturnsBusiness: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns'
      ),
      ROCCompliances: path(
        ROOTS.app,
        '/services/business-services/roc-compliances'
      ),
      PayrollCompliances: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances'
      ),
      Loans: path(ROOTS.app, '/services/business-services/loans'),
      FinancialAdviosryAndLegalServices: path(
        ROOTS.app,
        '/services/business-services/financial-advisory-and-legal-services'
      ),
      Investments: path(ROOTS.app, '/services/business-services/investments'),
      InsuranceAdvisory: path(
        ROOTS.app,
        '/services/business-services/insurance-advisory'
      ),
      OtherGovernmentRegistrations: path(
        ROOTS.app,
        '/services/business-services/other-govt-registrations'
      ),
      // payroll
      ESI: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/complinace'
      ),
      Registration: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/registration'
      ),
      PayrollPreparation: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/payroll-preparation'
      ),
      // PayrollProcessing: path(ROOTS.app, '/services/business-services/payroll-compliances/payroll-processing'),
      PF: path(ROOTS.app, '/services/business-services/payroll-compliances/pf'),
      PT: path(ROOTS.app, '/services/business-services/payroll-compliances/pt'),
      CompanyFilings: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company'
      ),
      OtherCompanyFilings: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/other-company-filings'
      ),
      OtherLLPFilings: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/other-llp-filings'
      ),
      LLPFilings: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/llp'
      ),
      AnnualFilingsCompany: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/annual'
      ),
      AnnualFilingsLLP: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/llp/annual'
      ),
      GSTRegistration: path(
        ROOTS.app,
        '/services/business-services/gst-services/gst-registration'
      ),
      EwayBillServices: path(
        ROOTS.app,
        '/services/business-services/gst-services/eway-bill-services'
      ),
      GSTCancellationAndRevocation: path(
        ROOTS.app,
        '/services/business-services/gst-services/gst-cancellation-revocation'
      ),
      HealthInsuranceBusiness: path(
        ROOTS.app,
        '/services/business-services/insurance-advisory/health'
      ),
      VehicleInsuranceBusiness: path(
        ROOTS.app,
        '/services/business-services/insurance-advisory/vehicle'
      ),
      AccountingAndBookkeeping: path(
        ROOTS.app,
        '/services/business-services/accounting-and-bookkeeping'
      ),
      AccountingServicesUnderPackage: path(
        ROOTS.app,
        '/services/business-services/accounting-services'
      ),
      ComplianceServicesUnderPackage: path(
        ROOTS.app,
        '/services/business-services/compliance-services'
      )
      // all: path(ROOTS.app, '/mail/all'),
      // labels: [
      //   path(ROOTS.app, '/mail/label/:customLabel/:mailId?'),
      //   path(ROOTS.app, '/mail/:systemLabel/:mailId?')
      // ]
    },
    //products
    products: {
      root: path(ROOTS.app, '/products')
    },

    //mis report routes
    MISReports: {
      root: path(ROOTS.app, '/mis-reports'),
      rootIndexPath: path(ROOTS.app, '/mis-reports/index'),
      RevenueReports: path(ROOTS.app, '/mis-reports/revenue-reports'),
      RatioAnalysisReports: path(
        ROOTS.app,
        '/mis-reports/ratio-analysis-reports'
      ),
      SalaryReport: path(ROOTS.app, '/mis-reports/salary-report'),
      RatioAnalysisChartReports: path(
        ROOTS.app,
        '/mis-reports/ratio-analysis-reports/chart-analysis'
      ),
      RatioAnalysisNumberReports: path(
        ROOTS.app,
        '/mis-reports/ratio-analysis-reports/number-analysis'
      ),
      BalanceSheetReport: path(ROOTS.app, '/mis-reports/balance-sheet-report'),
      PLBalanceSheetReport: path(
        ROOTS.app,
        '/mis-reports/profit-loss-and-balance-sheet-report'
      ),
      ExpensesReport: path(
        ROOTS.app,
        '/mis-reports/sales-credit-collection-selling-distribution-expenses'
      ),
      ProfitAndLossReport: path(ROOTS.app, '/mis-reports/profit-loss-report')
    },
    //navbar routes
    newPost: path(ROOTS.app, '/new-blog-post'),
    statutory: path(ROOTS.app, '/filling-status'),

    track: {
      trackService: path(ROOTS.app, '/track-your-services'),
      serviceStatus: path(ROOTS.app, '/track-your-services/service-status')
    },

    investment: path(ROOTS.app, '/my-investment'),
    insurance: path(ROOTS.app, '/my-insurance'),
    incomeTaxReturns: path(ROOTS.app, '/income-tax-returns'),
    info_and_docs: path(ROOTS.app, '/income-tax-returns/filings'),

    //query routes
    chat: {
      root: path(ROOTS.app, '/chat'),
      newQuery: path(ROOTS.app, '/chat/new-query'),
      myQueries: path(ROOTS.app, '/chat/my-queries')
    },

    documentWallet: {
      root: path(ROOTS.app, '/document-wallet'),
      folders: path(ROOTS.app, '/document-wallet/folder'),
      files: path(ROOTS.app, '/document-wallet/folders/files')
    },

    userDocumentWallet: {
      root: path(ROOTS.app, '/user-document-wallet'),
      folders: path(ROOTS.app, '/user-document-wallet/folder'),
      files: path(ROOTS.app, '/user-document-wallet/folders/files')
    },
    clientLoans: {
      root: path(ROOTS.app, '/loansform')
    },
    sales: {
      root: path(ROOTS.app, '/leads')
      //Remaing routs
    },

    timeTracking: path(ROOTS.app, '/time-tracking'),
    // notifications: path(ROOTS.app, '/notifications'),
    notifications: path(ROOTS.app, '/home'),
    requestCallback: path(ROOTS.app, '/requestCallback'),
    LoansView: path(ROOTS.app, '/loans'),
    notificationForm: path(ROOTS.app, '/notifications-form/:OrderId?'),
    employeeTimeTracking: path(ROOTS.app, '/employee-time-tracking'),
    compliances: path(ROOTS.app, '/compliances'),
    tagging: path(ROOTS.app, '/tagging'),
    viewTagging: path(ROOTS.app, '/viewTagging'),
    account: path(ROOTS.app, '/account'),
    // masterData: path(ROOTS.app, "/masterData"),
    masterData: {
      root: path(ROOTS.app, '/masterData'),
      departmentCategories: path(
        ROOTS.app,
        '/masterData/department-categories'
      ),
      employeeData: path(ROOTS.app, '/masterData/employee-data'),
      onboardingchecklistData: path(
        ROOTS.app,
        "/masterData/onboardingchecklistdata"
      ),
      Phase: path(ROOTS.app, "/masterData/phase"),
      Phasestep: path(ROOTS.app, "/masterData/phaseStep"),
      Status: path(ROOTS.app, "/masterData/status"),
      Entitytype: path(ROOTS.app, "/masterData/entitytype"),
      Industry: path(ROOTS.app, "/masterData/Industry"),
      Area: path(ROOTS.app, "/masterData/area"),
      SubArea: path(ROOTS.app, "/masterData/Subarea"),
      Step: path(ROOTS.app, "/masterData/Step"),
      Account: path(ROOTS.app, "/masterData/Account"),
      Checklist: path(ROOTS.app, "/masterData/Checklist"),
      template: path(ROOTS.app, "/masterData/template"),
      analysis: path(ROOTS.app, "/masterData/analysis"),
      sector: path(ROOTS.app, "/masterData/sector"),
      compliance: path(ROOTS.app, "/masterData/compliance"),
      department : path(ROOTS.app, "/masterData/department"),
      addDepartment : path(ROOTS.app, "/masterData/department/department-detail")
    },

    //Compliance Manager routes
    ComplianceManagerClient: {
      root: path(ROOTS.app, '/compliance-manager'),
      gstServices: path(ROOTS.app, '/compliance-manager/gst-services'),
      detailedReportsCards: path(
        ROOTS.app,
        '/compliance-manager/detailed-report'
      ),
      turnoverReconciliation: path(
        ROOTS.app,
        '/compliance-manager/gst-services/detailed-report/turnover-reconciliation'
      ),
      itcReconciliation: path(
        ROOTS.app,
        '/compliance-manager/gst-services/detailed-report/itc-reconciliation'
      ),
      purchaseReconciliation: path(
        ROOTS.app,
        '/compliance-manager/gst-services/detailed-report/purchase-reconciliation'
      ),
      filingStatus: path(
        ROOTS.app,
        '/compliance-manager/gst-services/filing-status'
      ),
      gst1Filing: path(
        ROOTS.app,
        '/compliance-manager/gst-services/gst1-filings'
      ),
      MonthlySummary: path(
        ROOTS.app,
        '/compliance-manager/gst-services/monthly-summary'
      ),
      incomeTaxServices: path(
        ROOTS.app,
        '/compliance-manager/income-tax-services'
      ),
      //tds services
      tdsServices: {
        root: path(ROOTS.app, '/compliance-manager/tds-services'),
        paymentStatus: {
          root: path(
            ROOTS.app,
            '/compliance-manager/tds-services/payment-status'
          ),
          challanDetails: path(
            ROOTS.app,
            '/compliance-manager/tds-services/payment-status/challan-details'
          )
        },
        fillingDetails: {
          root: path(
            ROOTS.app,
            '/compliance-manager/tds-services/filling-details'
          ),
          returnStatus: path(
            ROOTS.app,
            '/compliance-manager/tds-services/filling-details/return-status'
          )
        },
        reconciliation: {
          root: path(
            ROOTS.app,
            '/compliance-manager/tds-services/reconciliation'
          )
        },
        defaultSummary: {
          root: path(
            ROOTS.app,
            '/compliance-manager/tds-services/default-summary'
          )
        },
        downloadForm16: {
          root: path(
            ROOTS.app,
            '/compliance-manager/tds-services/download-form16'
          )
        }
      },
      //sebi services
      SEBIServices: {
        root: path(ROOTS.app, '/compliance-manager/sebi-services'),
        quarterly: path(
          ROOTS.app,
          '/compliance-manager/sebi-services/quarterly-based'
        ),
        halfYearly: path(
          ROOTS.app,
          '/compliance-manager/sebi-services/halfYearly-based'
        ),
        annual: path(
          ROOTS.app,
          '/compliance-manager/sebi-services/annual-based'
        ),
        eventBased: path(
          ROOTS.app,
          '/compliance-manager/sebi-services/event-based'
        )
      },

      //payroll services
      PayrollServices: {
        root: path(ROOTS.app, '/compliance-manager/payroll-services'),
        filingStatus: {
          root: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/filing-status'
          ),
          PF: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/filing-status/PF'
          ),
          PT: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/filing-status/PT'
          ),
          ESI: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/filing-status/ESI'
          )
        },
        notices: path(
          ROOTS.app,
          '/compliance-manager/payroll-services/notices'
        ),
        reconcilation: {
          root: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/reconcilation'
          ),
          PF: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/reconcilation/PF'
          ),
          PT: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/reconcilation/PT'
          ),
          ESI: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/reconcilation/ESI'
          ),
          DifferenceSheet: path(
            ROOTS.app,
            '/compliance-manager/payroll-services/reconcilation/DifferenceSheet'
          )
        },
        register: path(
          ROOTS.app,
          '/compliance-manager/payroll-services/register'
        )
      },

      //Roc Service
      ROCServices: {
        root: path(ROOTS.app, '/compliance-manager/roc-services'),
        filing: path(ROOTS.app, '/compliance-manager/roc-services/filing'),
        nonFiling: path(
          ROOTS.app,
          '/compliance-manager/roc-services/non-filing'
        ),
        declaration: path(
          ROOTS.app,
          '/compliance-manager/roc-services/declaration'
        ),
        registers: {
          root: path(ROOTS.app, '/compliance-manager/roc-services/registers'),
          directors: path(
            ROOTS.app,
            '/compliance-manager/roc-services/registers/directors'
          ),
          shareHolders: path(
            ROOTS.app,
            '/compliance-manager/roc-services/registers/shareHolders'
          ),
          liByCompanies: path(
            ROOTS.app,
            '/compliance-manager/roc-services/registers/liByCompanies'
          ),
          relatedParties: path(
            ROOTS.app,
            '/compliance-manager/roc-services/registers/relatedParties'
          ),
          kmp: path(ROOTS.app, '/compliance-manager/roc-services/registers/kmp')
        }
      },

      //FEMA service
      FEMAServices: {
        fema: path(ROOTS.app, '/compliance-manager/fema-services')
      },

      //IncomeTax service
      ITServices: {
        root: path(ROOTS.app, '/compliance-manager/it-services'),
        detailsOfTaxes: {
          root: path(
            ROOTS.app,
            '/compliance-manager/it-services/details-of-taxes'
          ),
          advanceTaxPaid: path(
            ROOTS.app,
            '/compliance-manager/it-services/details-of-taxes/advance-tax'
          ),
          otherTaxesPaid: path(
            ROOTS.app,
            '/compliance-manager/it-services/details-of-taxes/other-tax'
          ),
          tdstcsCredit: path(
            ROOTS.app,
            '/compliance-manager/it-services/details-of-taxes/tdstcs-credit'
          )
        },
        notices: {
          root: path(ROOTS.app, '/compliance-manager/it-services/notices'),
          intimations: path(
            ROOTS.app,
            '/compliance-manager/it-services/notices/intimations'
          ),
          scrutiny: path(
            ROOTS.app,
            '/compliance-manager/it-services/notices/scrutiny'
          ),
          assessment: path(
            ROOTS.app,
            '/compliance-manager/it-services/notices/assessment'
          ),
          returnFiling: path(
            ROOTS.app,
            '/compliance-manager/it-services/notices/return-filing'
          ),
          adjustingRefund: path(
            ROOTS.app,
            '/compliance-manager/it-services/notices/adjusting-refund'
          ),
          questionResponse: path(
            ROOTS.app,
            '/compliance-manager/it-services/notices/question-response'
          )
        },
        filingStatus: path(
          ROOTS.app,
          '/compliance-manager/it-services/filing-status'
        )
      }
    },

    //Firm User Management
    firmUserManagement: {
      root: path(ROOTS.app, '/firm-users')
    },

    //client management routes

    ClientManagement: {
      root: path(ROOTS.app, '/clients'),
      clientManagement: path(ROOTS.app, '/client-management'),
      clientRegistration: path(ROOTS.app, '/client-registration'),
      clientSelection: path(ROOTS.app, '/clients/:clientId?'),
      misForms: path(ROOTS.app, '/clients/:clientId/mis-forms'),
      // business: path(ROOTS.app, "/clients/:clientId/business"),
      business: {
        root: path(ROOTS.app, '/clients/:clientId/business'),
        businesslist: path(ROOTS.app, '/businessess'),
        clientBusinessProfile: path(
          ROOTS.app,
          '/clients/:clientId/clientBusinessProfile'
        ),
        businessProfile: path(
          ROOTS.app,
          '/clients/:clientId/clientBusinessProfile/businessProfile'
        )
      },
      reviewStatusCard: path(ROOTS.app, '/clients/:clientId/review-tracking'),
      businessForm: path(
        ROOTS.app,
        '/clients/:clientId/business/business-form'
      ),
      representativeForm: path(
        ROOTS.app,
        '/clients/:clientId/business/representative-form'
      ),
      complainceManagerCards: path(
        ROOTS.app,
        '/clients/:clientId/compliance-manager'
      ),
      trackYourService: path(
        ROOTS.app,
        '/clients/:clientId/track-your-service'
      ),
      serviceStatus: path(
        ROOTS.app,
        '/clients/:clientId/track-your-service/:orderId'
      ),
      complianceManager: {
        gstServices: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services'
          ),
          monthlySummary: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/monthly-summary'
          ),
          detailedReport: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/detailed-reports'
          ),
          filingStatus: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/filing-status/'
          ),
          GST3BForm: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst3bform'
          ),

          //GSTR9
          gst9Overview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing'
          ),
          gst9AdvancesOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/advances'
          ),
          gst9OutwardSuppliesOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/outward-supplies'
          ),
          gst9ItcAvailedOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/itc-availed'
          ),
          gst9ItcReversedOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/itc-reversed'
          ),
          gst9ItcOtherOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/itc-other'
          ),
          gst9TaxPaidOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/tax-paid'
          ),
          gst9ParticularsofTransactionsOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/particularsof-transactions'
          ),
          gst9DifferentialTaxOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/differential-tax'
          ),
          gst9DemandsandRefundsOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/demands-refunds'
          ),
          gst9CompositionTaxpayersOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/composition-taxpayers'
          ),
          gst9HsnOutwardSummaryOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/hsnoutward-summary'
          ),
          gst9HsnInwardSummaryOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst9-filing/hsninward-sumary'
          ),

          //GSTR1
          gst1Overview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing'
          ),
          gst1B2BOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/b2b'
          ),
          gst1B2CLOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/b2cl'
          ),
          gst1CDRNOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/cdnr'
          ),
          gst1CDNUROverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/cdnur'
          ),
          gst1ExportsOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/exports'
          ),
          gst1B2CSOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/b2cs'
          ),
          gst1NilRatedOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/nil-rated'
          ),
          gst1TaxLiabilityOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/tax-liability'
          ),
          gst1AdjustmentAdvancesOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/adjustment-advances'
          ),
          gst1HSNOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/hsn'
          ),
          gst1DocumentsOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/documents'
          ),
          gst1SuppliesMade: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/supplies_made'
          ),
          gst1SuppliesUnder95: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/supplies_under_95'
          ),

          gst1B2BAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/b2ba'
          ),
          gst1B2CLAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/b2cla'
          ),
          gst1CDRNAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/cdnra'
          ),
          gst1CDNURAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/cdnura'
          ),
          gst1ExportsAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/expa'
          ),
          gst1B2CSAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/b2csa'
          ),
          gst1asm: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/asm'
          ),
          gst1asu: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/asu'
          ),
          gst1TaxLiabilityAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/tl-amend'
          ),
          gst1AdjustmentAdvancesAOverview: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/gst-services/gst1-filing/aa-amend'
          ),
          GSTR3Bvs1vsBooks: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation'
            ),
            overAllSummary: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation/overall-summary'
            ),
            detailedReport: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation/detailed-report'
            ),
            annexureOverallSummary: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation/annexure-overall-summary'
            ),
            annexureTaxableSupplies: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation/annexure-taxable-supplies'
            ),
            annexureZeroRated: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation/annexure-zero-rated'
            ),
            annexureOthers: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/turnover-reconciliation/annexure-others'
            )
          },
          GSTR3Bvs2bvsBooks: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/itc-reconciliation'
            ),
            overAllSummary: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/itc-reconciliation/overall-summary'
            ),
            annexure: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/itc-reconciliation/annexure'
            )
          },
          GSTR2avsPurchases: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/purchase-reconciliation'
            ),
            annexure: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/purchase-reconciliation/annexure'
            ),
            summary: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/purchase-reconciliation/summary'
            ),
            purchaseInvoice: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/purchase-reconciliation/purchase-invoice'
            ),
            purchaseCreditDebitNote: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/purchase-reconciliation/purchase-credit-debit-note'
            ),
            summary: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/gst-services/detailed-reports/purchase-reconciliation/summary'
            )
          }
        },
        tdsServices: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services'
          ),
          challanDeductee: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services/challan-deductee/'
          ),
          addChallan: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services/challan-deductee/add-challan'
          ),
          tdsFillings: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services/fillings/'
          ),
          defaultSummary: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services/default-summary/'
          ),
          uploadForm16A: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services/upload-form-16/'
          ),
          tdsReconciliation: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tds-services/tds-reconciliation/'
          ),
          tdsFilings: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/tds-services/tds-filings/'
            ),
            payment: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/tds-services/tds-filings/payment'
            ),
            masterData: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/tds-services/tds-filings/master-data'
            ),

            filing: {
              root: path(
                ROOTS.app,
                '/clients/:clientId/compliance-manager/tds-services/tds-filings/filing'
              ),
              form26q: path(
                ROOTS.app,
                '/clients/:clientId/compliance-manager/tds-services/tds-filings/filing/form26q'
              ),
              form24q: path(
                ROOTS.app,
                '/clients/:clientId/compliance-manager/tds-services/tds-filings/filing/form24q'
              )
            }
          }
        },
        rocService: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/roc-services'
          ),
          mcaFiling: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/roc-services/mca-filing/'
          ),
          mcaNonFiling: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/roc-services/mca-nonFiling'
          ),

          declaration: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/roc-services/declaration'
          ),
          registers: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/roc-services/registers'
            ),
            directors: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/roc-services/registers/directors'
            ),
            shareHolders: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/roc-services/registers/share-holders'
            ),
            loansInvestmentsbycompanies: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/roc-services/registers/LI-companies'
            ),
            relatedParties: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/roc-services/registers/related-parties'
            ),
            kmp: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/roc-services/registers/kmp'
            )
          }
        },
        //fema employee
        femaService: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/fema-services'
          )
        },
        advanceTaxService: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/advance-tax'
          )
        },
        assesseedetails: {
          root: path(
            ROOTS.app,
            // "/clients/:clientId/compliance-manager/assessee-details"
            '/clients/:clientId/compliance-manager/assessee-details/:id'
          )
        },
        taxcalculation: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/tax-calculation'
          )
        },
        advancetaxdetails: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/advance-taxdetails'
          )
        },
        advancetaxhistory: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/advance-taxhistory'
          )
        },
        //Income Tax employee
        itService: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/it-services'
          ),
          filingStatus: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/it-services/filing-status'
          ),
          detailsOfTaxes: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/details-of-taxes'
            ),
            advanceTaxPaid: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/details-of-taxes/adv-tax-paid'
            ),
            otherTaxPaid: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/details-of-taxes/other-tax-paid'
            ),
            tdstcsCredit: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/details-of-taxes/tdstcs-credit'
            )
          },
          notices: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices'
            ),
            adjustingRefund: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices/adjusting-refund'
            ),
            assessmentReAssessment: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices/assessment-reAssessment'
            ),
            intimations: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices/intimations'
            ),
            returnFiling: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices/return-filing'
            ),
            scrutiny: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices/scrutiny'
            ),
            QandA: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/it-services/notices/QandA'
            )
          }
        },
        //payroll employee
        payrollService: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/payroll-services'
          ),
          statutoryFiling: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/statutory-filing'
            ),
            esi: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/statutory-filing/esi'
            ),
            pf: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/statutory-filing/pf'
            ),
            pt: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/statutory-filing/pt'
            )
          },
          reconciliation: {
            root: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/reconciliation'
            ),
            esi: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/reconciliation/esi'
            ),
            pf: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/reconciliation/pf'
            ),
            pt: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/reconciliation/pt'
            ),
            differenceSheet: path(
              ROOTS.app,
              '/clients/:clientId/compliance-manager/payroll-services/reconciliation/difference-sheet'
            )
          },
          notices: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/payroll-services/notices'
          ),
          register: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/payroll-services/register'
          )
          // providentFund: path(
          //   ROOTS.app,
          //   '/clients/:clientId/compliance-manager/payroll-services/provident-fund/'
          // ),
          // employeeStateInsurance: path(
          //   ROOTS.app,
          //   '/clients/:clientId/compliance-manager/payroll-services/employee-state-insurance/'
          // ),
          // professionalTax: path(
          //   ROOTS.app,
          //   '/clients/:clientId/compliance-manager/payroll-services/professional-tax/'
          // ),
          // espo: path(
          //   ROOTS.app,
          //   '/clients/:clientId/compliance-manager/payroll-services/espo/'
          // )
        },
        sebiService: {
          root: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/sebi-services'
          ),
          quarterly: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/sebi-services/quarterly'
          ),
          halfYearly: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/sebi-services/half-yearly'
          ),
          annual: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/sebi-services/annual'
          ),
          eventBased: path(
            ROOTS.app,
            '/clients/:clientId/compliance-manager/sebi-services/event-based'
          )
        }
      },

      reviewStatus: {
        preliminaryData: path(
          ROOTS.app,
          '/clients/:clientId/review-tracking/preliminary-data'
        ),
        accounting: path(
          ROOTS.app,
          '/clients/:clientId/review-tracking/accounting'
        ),
        compliance: path(
          ROOTS.app,
          '/clients/:clientId/review-tracking/compliance'
        ),
        summary: path(ROOTS.app, '/clients/:clientId/review-tracking/summary')
      },

      // preliminaryData: path(
      //   ROOTS.app,
      //   '/clients/:clientId/mis-forms/preliminary-data'
      // ),

      //mis form routes
      P$LAccount: path(ROOTS.app, '/clients/:clientId/mis-forms/p&l-account'),
      MISReports: path(ROOTS.app, '/clients/:clientId/mis-forms/mis-reports'),
      // P$LAccount: path(ROOTS.app, '/clients/:clientId/mis-forms/p&l-account'),
      MISReports: path(ROOTS.app, '/clients/:clientId/mis-forms/mis-reports'),
      createPLReport: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/P&L-report'
      ),
      misBalanceAccount: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/balance-account'
      ),
      misBudgetsVsActuals: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/budgets-actuals'
      ),
      misBudgetsVsActualsDataSheet: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/budgets-actuals/data-sheet'
      ),

      misBudgetsVsActualsPL: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/budgets-actuals/pl-sheet'
      ),
      balanceAccountPreliminaryData: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/balance-account/preliminary-data'
      ),
      balanceAccountBalanceSheet: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/balance-account/balance-sheet'
      ),
      balanceAccountBalanceSheetReport: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/balance-sheet-report'
      ),
      workingCapitalManagementReport: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/working-capital-report'
      ),
      balanceAccountDataSheet: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/balance-account/data-sheet'
      ),
      createPLForm: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/p&l-account/pl-sheet-form'
      ),
      createPLDataSheetForm: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/p&l-account/data-sheet-form'
      ),
      createPreliminaryForm: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/p&l-account/preliminary-data-form'
      ),
      createDataSheet: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/data-sheet-form'
      ),
      createBalanceSheetForm: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/balance-sheet-form'
      ),
      createReportForm: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/reports-form'
      ),
      balanceSheet: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/balance-sheet'
      ),

      //budgets vs actuals
      budgetsVsactuals: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/budgets-actuals/preliminary-data'
      ),

      //MIS Reports
      misReports: path(ROOTS.app, '/clients/:clientId/mis-forms/mis-reports'),
      // cashFlowSheet: path(
      //   ROOTS.app,
      //   '/clients/:clientId/mis-forms/cashflow-sheet'
      // ),
      cashFlowReports: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/cashflow-report'
      ),
      oprRevenueReports: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/opr-revenue-report'
      ),
      expenseAnalysisReports: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/expense-analysis-report'
      ),
      budgetActualsReports: path(
        ROOTS.app,
        '/clients/:clientId/mis-forms/mis-reports/budget-actual-report'
      )
    },

    ///// anand
    BusinessIncorporations: {
      Privatelimitedcompany: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/private-limited-company'
      ),
      OPCIncorporation: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/one-person-company'
      ),
      LLPIncorporation: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/llp'
      ),
      Partnershipfirm: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/partnership-firm'
      ),
      SoleProprietorshipIncorporation: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/sole-proprietor'
      ),
      Sec8CompanyIncorporationView: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/section-8-company'
      ),

      ProducerCompanyView: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/producer-company'
      ),
      NidhiCompanyView: path(
        ROOTS.app,
        '/services/business-services/business-incorporation/nidhi-company'
      ),
      CompanyIncorporations: {
        // PvtLtdCompanyIncorporation: path(
        //   ROOTS.app,
        //   '/app/services/business-services/business-incorporation/company/private-limited-company'
        // ),
        // OPCIncorporation: path(
        //   ROOTS.app,
        //   '/services/business-services/business-incorporation/company/one-person-company'
        // )
      }
    },

    OtherGovtRegistrations: {
      TrustDetails: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/trust'
      ),
      MSMERegistration: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/msme-registration'
      ),
      IECRegistration: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/iec-registration'
      ),
      FoodLicenseRegistration: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/fssai-registration'
      ),
      TradeLicense: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/trade-registration'
      ),

      DigitalSignature: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/digital-signature'
      ),
      Labourlicense: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/labour-license'
      ),

      PANRegistration: path(
        ROOTS.app,
        '/services/business-services/other-govt-services/pan-registration'
      )
      // Form15CAAnd15CB: path(
      //   ROOTS.app,
      //   '/services/business-services/other-govt-services/form-15ca-15cb'
      // ),

      // ShopsAndEshtablishments: path(
      //   ROOTS.app,
      //   '/services/business-services/other-govt-services/shop-establishment'
      // ),
      // TrademarkRegistration: path(
      //   ROOTS.app,
      //   '/services/business-services/other-govt-services/trademark-registration'
      // )
    },
    //gst
    GSTServices: {
      registrationIndividual: path(
        ROOTS.app,
        '/services/business-services/gst-services/gst-registration-individual'
      ),
      GSTR9AnuualReturn: path(
        ROOTS.app,
        '/services/business-services/gst-services/gstr9'
      ),
      EwayBillRegistration: path(
        ROOTS.app,
        '/services/business-services/gst-services/eway-bill-services/registration'
      ),
      GSTCancellation: path(
        ROOTS.app,
        '/services/business-services/gst-services/gst-cancellation-revocation/cancellation'
      ),
      AmendmentInGSTRegistration: path(
        ROOTS.app,
        '/services/business-services/gst-services/amendment-gst-registration'
      ),
      GSTNotice: path(
        ROOTS.app,
        '/services/business-services/gst-services/gst-notice'
      ),
      LUTFilings: path(
        ROOTS.app,
        '/services/business-services/gst-services/lut-filings'
      ),
      Reconcilliations: path(
        ROOTS.app,
        '/services/business-services/gst-services/reconcilliations'
      )

      // registrationcompany: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/gst-registration-company'
      // ),
      // GSTReturns: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/gst-returns'
      // ),
      // GSTRegistrationIndividualForm: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/gst-registration-individual-form/:OrderId?'
      // ),
      // GSTRegistrationCompanyForm: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/gst-registration-company-form/:OrderId?'
      // ),
      // GSTReturnsForm: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/gst-returns/gst-returns-form/:OrderId?'
      // ),
      // EwayBillGeneration: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/eway-bill-services/generation'
      // ),

      // GSTRevocation: path(
      //   ROOTS.app,
      //   '/services/business-services/gst-services/gst-cancellation-revocation/revocation'
      // ),
    },
    // tds
    PaymentinTDS: {
      PaymentsinTDS: path(ROOTS.app, '/services/business-services/tds/Payments')
    },
    TDSServices: {
      Form24Q: path(ROOTS.app, '/services/business-services/tds-services/24Q'),
      Form26Q: path(ROOTS.app, '/services/business-services/tds-services/26Q'),
      Form26QB: path(
        ROOTS.app,
        '/services/business-services/tds-services/26QB'
      ),
      Form27Q: path(ROOTS.app, '/services/business-services/tds-services/27Q'),
      Form27EQ: path(ROOTS.app, '/services/business-services/tds-services/27EQ')
    },
    // income tax filings
    IncomeTaxReturnsBusiness: {
      IndividualsIncomeTaxReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/IndividualsIncomeTaxReturns'
      ),
      SoleProprietorIncomeTaxReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/SoleProprietor'
      ),
      PrivateLimitedCompanyIncomeTaxReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/PrivateLimitedCompany'
      ),
      PatnershipFirmTaxReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/patnership-firm-returns'
      ),

      PatnershipLLPReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/patnership-llp-returns'
      ),
      TrustReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/trust-returns'
      ),
      SocietyReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/society-returns'
      ),
      Sec8CompanyIncomeTaxReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/Sec8Company'
      ),
      OnePersonCompanyIncomeTaxReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/OPC'
      ),
      NidhiCompanyReturns: path(
        ROOTS.app,
        '/services/business-services/income-tax-returns/nidhi-company-returns'
      )

      // CompanyReturns: path(
      //   ROOTS.app,
      //   '/services/business-services/income-tax-returns/company-returns'
      // ),

      // ProducerCompanyReturns: path(
      //   ROOTS.app,
      //   '/services/business-services/income-tax-returns/producer-company-returns'
      // ),

      // IndividualsIncomeTaxReturnsForm: path(
      //   ROOTS.app,
      //   '/services/business-services/income-tax-returns/IndividualsIncomeTaxReturns/:OrderId?'
      // )
    },
    // payroll compliances
    PayrollCompliances: {
      PayrollPreparation: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/preparation'
      ),
      PayrollGeneration: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/generation'
      ),
      Form16Generation: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/form16generation'
      ),
      EmployeeAgreements: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/employeeagreements'
      ),
      SalaryStructure: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/salary-structure'
      ),
      ESOPStructure: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/esop-structure'
      ),
      ESIRegistration: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/esi/registration'
      ),
      ProvidentFund: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/provident-fund'
      ),
      ProfessionalTax: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/professional-tax'
      ),
      ESIReturnFiling: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/esi/return-filing'
      ),
      POSH: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/posh'
      ),
      Bonus: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/Bonus'
      ),
      Gratuity: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/Gratuity'
      ),
      PaymentAndReturn: path(
        ROOTS.app,
        '/services/business-services/payroll-compliances/payment-and-return'
      )

      // Compliance: path(ROOTS.app,
      //   '/services/business-services/payroll-compliances/payroll-compliances'),
      // Registration: path(ROOTS.app,
      //   '/services/business-services/payroll-compliances/registration'),
      // payrollprocessingservice: path(
      //   ROOTS.app,
      //   '/services/business-services/payroll-compliances/processing'
      // ),
      // PFFiling: path(
      //   ROOTS.app,
      //   '/services/business-services/payroll-compliances/pf/filing'
      // ),

      // PFRegistration: path(
      //   ROOTS.app,
      //   '/services/business-services/payroll-compliances/pf/registration'
      // ),
      // PTRegistration: path(
      //   ROOTS.app,
      //   '/services/business-services/payroll-compliances/pt/registration'
      // ),
      // PTReturnFiling: path(
      //   ROOTS.app,
      //   '/services/business-services/payroll-compliances/pt/filing'
      // ),
    },
    // roc
    ROCCompliances: {
      CommencementOfBusiness: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/commencement-of-business'
      ),
      AuditorAppointment: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/auditor-appointment'
      ),
      AOC4: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/annual/aoc4'
      ),
      MGT7: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/annual/mgt7'
      ),
      DirectorReport: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/annual/directors-report'
      ),
      Form11: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/llp/annual/form11'
      ),
      Form8: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/llp/annual/form8'
      ),
      DIR3KYC: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/annual/dir3kyc'
      ),
      DirectorAppointment: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/director-appointment'
      ),
      DirectorRemoval: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/director-removal'
      ),
      AuditorRemoval: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/auditor-removal'
      ),
      ChangeInAuthorizedShareCapital: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/change-authorized-share-capitol'
      ),
      ChangeInObjects: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/change-in-object'
      ),
      AddDesignatedPartner: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/add-designated-Partner'
      ),
      ChangestoLLPAgreement: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/changes-to-llp-agreement'
      ),
      LLPClosing: path(
        ROOTS.app,
        '/services/business-services/roc-compliances/company/llp-closing'
      )

      // DPT3: path(
      //   ROOTS.app,
      //   '/services/business-services/roc-compliances/company/dpt3'
      // ),
    },

    // accounting and compliance
    AccountingandComplinaces: {
      BookKeeping: path(ROOTS.app, '/services/business-services/bookkeeping'),
      MonthEndReviews: path(
        ROOTS.app,
        '/services/business-services/month-end-reviews'
      ),
      MISReport: path(ROOTS.app, '/services/business-services/mis-report'),
      PayrollMaintanance: path(
        ROOTS.app,
        '/services/business-services/payroll-maintanance'
      ),
      PreparingAndFilingofStatutoryPaymentsandReturns: path(
        ROOTS.app,
        '/services/business-services/PreparingAndFilingofStatutoryPaymentsandReturns'
      )
    },
    // Audit
    Audit: {
      StatutoryAudit: path(
        ROOTS.app,
        '/services/business-services/statutory-audit'
      ),
      InternalAudit: path(
        ROOTS.app,
        '/services/business-services/internal-audit'
      ),
      TaxAudit: path(ROOTS.app, '/services/business-services/tax-audit'),
      SystemsAudit: path(
        ROOTS.app,
        '/services/business-services/systems-audit'
      ),
      ManagementAudit: path(
        ROOTS.app,
        '/services/business-services/management-audit'
      ),
      AgreedUponProcedures: path(
        ROOTS.app,
        '/services/business-services/agreed-upon-procedures'
      ),
      CertificationServices: path(
        ROOTS.app,
        '/services/business-services/CertificationServices'
      ),
      SpecialAudits: path(
        ROOTS.app,
        '/services/business-services/SpecialAudits'
      )
    },

    /////

    Loans: {
      HomeLoan: path(ROOTS.app, '/services/business-services/loans/home'),
      TermLoan: path(ROOTS.app, '/services/business-services/loans/term'),
      VehicleLoan: path(ROOTS.app, '/services/business-services/loans/vehicle'),
      WorkingCapitolLoan: path(
        ROOTS.app,
        '/services/business-services/loans/working-capitol'
      )
    },
    FinancialAdviosryAndLegalServices: {
      DraftingOfDocuments: path(
        ROOTS.app,
        '/services/business-services/financial-advisory-and-legal-services/drafting-documents'
      ),
      LegalReview: path(
        ROOTS.app,
        '/services/business-services/financial-advisory-and-legal-services/legal-review'
      )
    },

    InsuranceAdvisory: {
      CoronavirusInsurance: 'https://www.gibl.in/coronavirus-insurance/',
      HealthInsurance: 'https://www.gibl.in/health-insurance/',
      CommercialVehicle: 'https://www.gibl.in/commercial-vehicle-insurance/',
      CarInsurance: 'https://www.gibl.in/car-insurance/',
      TwoWheelerInsurance: 'https://www.gibl.in/two-wheeler-insurance/'
    },

    //INDIVIDUAL SERVICES
    IncomeTaxReturns: {
      SalariedEmployee: {
        // LessThan50Lakh: path(ROOTS.app, '/services/individual-services/income-tax-returns/salaried-employee/SP-HP-OI'),
        MoreThan50Lakh: path(
          ROOTS.app,
          '/services/individual-services/income-tax-returns/salaried-employee/more-than-50'
        ),
        SalaryHousePropertyOtherIncome: path(
          ROOTS.app,
          '/services/individual-services/income-tax-returns/salaried-employee/SalaryHouseOtherIncome'
        ),
        SalaryHousePropertyCapitalGainOtherIncome: path(
          ROOTS.app,
          '/services/individual-services/income-tax-returns/salaried-employee/SalaryHouseCapitalGainOtherIncome'
        ),
        // MoreThan50LakhForm : path(ROOTS.app, '/services/individual-services/income-tax-returns/salaried-employee/more-than-50'),
        SalaryHousePropertyOtherIncomeForm: path(
          ROOTS.app,
          '/services/individual-services/income-tax-returns/salaried-employee/SalaryHouseOtherIncome/:OrderId?'
        ),
        SalaryHousePropertyCapitalGainOtherIncomeForm: path(
          ROOTS.app,
          '/services/individual-services/income-tax-returns/salaried-employee/SalaryHouseCapitalGainOtherIncome/:OrderId?'
        )
      }
    },

    TDSOnSaleOfProperty: {
      Form26QB: path(
        ROOTS.app,
        '/services/individual-services/tds-on-sale-of-property/26qb'
      )
    },

    FinancialAndLegalAdvisory: {
      DraftingOfDocuments: path(
        ROOTS.app,
        '/services/individual-services/financial-legal-services/drafting-documents'
      ),
      LegalReview: path(
        ROOTS.app,
        '/services/individual-services/financial-legal-services/legal-review'
      )
    },

    InsuranceServices: {
      CoronavirusInsurance: path(
        ROOTS.app,
        '/views/finance-box/ServiceViews/Forms/CoronaVirusInsuranceForm'
      ),
      HealthInsurance: path(
        ROOTS.app,
        '/views/finance-box/ServiceViews/Forms/HealthInsuranceForm'
      ),
      CommercialVehicle: 'https://www.gibl.in/commercial-vehicle-insurance/',
      CarInsurance: 'https://www.gibl.in/car-insurance/',
      TwoWheelerInsurance: 'https://www.gibl.in/two-wheeler-insurance/'
    },

    LoanServices: {
      HomeLoan: path(
        ROOTS.app,
        '/services/individual-services/loan-services/home'
      ),
      TermLoan: path(
        ROOTS.app,
        '/services/individual-services/loan-services/term'
      ),
      VehicleLoan: path(
        ROOTS.app,
        '/services/individual-services/loan-services/vehicle'
      ),
      WorkingCapitolLoan: path(
        ROOTS.app,
        '/services/business-services/loan-services/working-capitol'
      )
    }
  },
  // Temporary app2 paths defined

  main: {
    root: path(ROOTS.app, '/services'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  app: {
    mail: {
      root: path(ROOTS.app, '/mail'),
      all: path(ROOTS.app, '/mail/all'),
      labels: [
        path(ROOTS.app, '/mail/label/:customLabel/:mailId?'),
        path(ROOTS.app, '/mail/:systemLabel/:mailId?')
      ]
    },
    chat: {
      root: path(ROOTS.app, '/chat'),
      new: path(ROOTS.app, '/chat/new'),
      conversation: [
        path(ROOTS.app, '/chat/new'),
        path(ROOTS.app, '/chat/:conversationKey')
      ]
    },
    calendar: path(ROOTS.app, '/calendar')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      profile: path(ROOTS.app, '/management/user/profile'),
      cards: path(ROOTS.app, '/management/user/card'),
      list: path(ROOTS.app, '/management/user/list'),
      account: path(ROOTS.app, '/management/user/account')
    },
    eCommerce: {
      root: path(ROOTS.app, '/management/e-commerce'),
      products: path(ROOTS.app, '/management/e-commerce/products'),
      product: path(ROOTS.app, '/management/e-commerce/product/:name'),
      productById: path(
        ROOTS.app,
        '/management/e-commerce/product/nike-air-force-1-ndestrukt'
      ),
      list: path(ROOTS.app, '/management/e-commerce/list'),
      checkout: path(ROOTS.app, '/management/e-commerce/checkout'),
      invoice: path(ROOTS.app, '/management/e-commerce/invoice')
    },
    blog: {
      root: path(ROOTS.app, '/management/blog'),
      post: path(ROOTS.app, '/management/blog/post/:title'),
      postById: path(
        ROOTS.app,
        '/management/blog/post/portfolio-review-is-this-portfolio-too-creative'
      ),
      newPost: path(ROOTS.app, '/management/blog/new-post')
    }
  },
  foundations: {
    root: path(ROOTS.app, '/foundations'),
    colors: path(ROOTS.app, '/foundations/colors'),
    typography: path(ROOTS.app, '/foundations/typography'),
    shadows: path(ROOTS.app, '/foundations/shadows'),
    grid: path(ROOTS.app, '/foundations/grid'),
    icons: path(ROOTS.app, '/foundations/icons')
  },
  components: {
    root: path(ROOTS.app, '/components'),
    accordion: path(ROOTS.app, '/components/accordion'),
    alert: path(ROOTS.app, '/components/alert'),
    autocomplete: path(ROOTS.app, '/components/autocomplete'),
    avatar: path(ROOTS.app, '/components/avatars'),
    badge: path(ROOTS.app, '/components/badges'),
    breadcrumbs: path(ROOTS.app, '/components/breadcrumbs'),
    buttons: path(ROOTS.app, '/components/buttons'),
    chip: path(ROOTS.app, '/components/chips'),
    dialog: path(ROOTS.app, '/components/dialogs'),
    textfield: path(ROOTS.app, '/components/text-fields'),
    label: path(ROOTS.app, '/components/labels'),
    lists: path(ROOTS.app, '/components/lists'),
    menu: path(ROOTS.app, '/components/menu'),
    pagination: path(ROOTS.app, '/components/pagination'),
    pickers: path(ROOTS.app, '/components/pickers'),
    popover: path(ROOTS.app, '/components/popover'),
    progress: path(ROOTS.app, '/components/progress'),
    rating: path(ROOTS.app, '/components/rating'),
    selectionControls: path(ROOTS.app, '/components/selection-controls'),
    snackbar: path(ROOTS.app, '/components/snackbars'),
    slider: path(ROOTS.app, '/components/slider'),
    stepper: path(ROOTS.app, '/components/steppers'),
    tabs: path(ROOTS.app, '/components/tabs'),
    table: path(ROOTS.app, '/components/table'),
    timeline: path(ROOTS.app, '/components/timeline'),
    tooltip: path(ROOTS.app, '/components/tooltips'),
    transferList: path(ROOTS.app, '/components/transfer-list'),
    treeView: path(ROOTS.app, '/components/tree-view'),

    // Extra
    chart: {
      root: path(ROOTS.app, '/extra-components/chart'),
      apexcharts: path(ROOTS.app, '/extra-components/chart/apexcharts'),
      recharts: path(ROOTS.app, '/extra-components/chart/recharts')
    },
    map: {
      root: path(ROOTS.app, '/extra-components/map'),
      google: path(ROOTS.app, '/extra-components/map/googlemap'),
      mapbox: path(ROOTS.app, '/extra-components/map/mapbox')
    },
    editor: path(ROOTS.app, '/extra-components/editor'),
    copyToClipboard: path(ROOTS.app, '/extra-components/copy-to-clipboard'),
    upload: path(ROOTS.app, '/extra-components/upload'),
    carousel: path(ROOTS.app, '/extra-components/carousel'),
    multiLanguage: path(ROOTS.app, '/extra-components/multi-language')
  }
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, '/introduction'),
  started: path(ROOTS.docs, '/getting-started'),
  // Theme UI
  color: path(ROOTS.docs, '/color'),
  typography: path(ROOTS.docs, '/typography'),
  icon: path(ROOTS.docs, '/icon'),
  shadows: path(ROOTS.docs, '/shadows'),
  components: path(ROOTS.docs, '/components'),
  important: path(ROOTS.docs, '/important'),

  // Development
  routing: path(ROOTS.docs, '/routing'),
  environmentVariables: path(ROOTS.docs, '/environment-variables'),
  stateManagement: path(ROOTS.docs, '/state-management'),
  apiCalls: path(ROOTS.docs, '/api-calls'),
  analytics: path(ROOTS.docs, '/analytics'),
  authentication: path(ROOTS.docs, '/authentication'),
  multiLanguage: path(ROOTS.docs, '/multi-language'),
  formHelper: path(ROOTS.docs, '/form-helper'),

  // Changelog
  support: path(ROOTS.docs, '/support'),
  changelog: path(ROOTS.docs, '/changelog')
};
