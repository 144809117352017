import React, { useRef, useState, useEffect } from "react";
// import { services } from '~/servicesConstants';
import { makeStyles } from "@material-ui/core/styles";
import { PATH_HOME, PATH_APP } from "~/routes/paths";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BusinessIncorporations } from "~/servicesConstants";
import { BusinessIncorporationsRegistrations } from "~/servicesConstants";
import { GSTRegistration } from "~/servicesConstants";
import { AnnualFilingsLLP } from "~/servicesConstants";
import { OtherCompanyFilings } from "~/servicesConstants";
import { OtherLLPFilings } from "~/servicesConstants";
import { TDSServices } from "~/servicesConstants";
import { IncomeTaxReturnsBusiness } from "~/servicesConstants";
import { Audit } from "~/servicesConstants";
import { PaymentsinTDS } from "~/servicesConstants";
import { ComplianceServices } from "~/servicesConstants";
import { payrollprocessingservice } from "~/servicesConstants";
import { Registration } from "~/servicesConstants";
import { PaymentAndReturn } from "~/servicesConstants";
import { Compliance } from "~/servicesConstants";
import { AccountingServices } from "~/servicesConstants";
import { MandatoryCompanyFilings } from "~/servicesConstants";
import { getlandingpageData } from "~/redux/slices/landingpageslice";
import { BASE_URL } from "~/constants";
import Page404View from "./views/errors/Page404View";
import { RETURN_TYPE } from "./views/finance-box/Clients/ComplianceManager/TDSServices/TDSServiceConstants";
import { resetLandingPageData } from "~/redux/slices/landingpageslice";
function Servicesloader({ loading, setLoading, setErrorPage }) {
  const dispatch = useDispatch();
  const [isEndNode, setisEndNode] = useState(null);
  const [headerData, setHeaderData] = useState({});
  const [domainName, setDomainName] = useState(window.location.host);
  const [res, setRes] = useState({});
  useEffect(() => {
    if (isEndNode !== null) {
      var a = document.createElement("a");
      a.setAttribute("href", isEndNode.href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [isEndNode]);
  useEffect(() => {
    dispatch(resetLandingPageData());
    // let url = `user_management/customisation_url?url=paadarathi.financebox.in`;
    let url = `user_management/customisation_url?url=${domainName}`;
    axios
      .get(BASE_URL + url)
      .then(res => {
        // console.log(res)
        setRes(res);
        dispatch(resetLandingPageData());
        setHeaderData(res?.data?.customisationSections?.header);
        // dispatch(getlandingpageData({ resObj: res, serviceConstants: {} }));
      })
      .catch(error => {
        console.log("error");
        dispatch(resetLandingPageData());

        setRes(null);
        setErrorPage(null);
        // dispatch(getlandingpageData({ resObj: {}, serviceConstants: {} }));
      });
    setLoading(false);
  }, []);

  const [services, setServices] = useState({
    heading: "Services",
    breadCrumbs: [{ name: "Services", href: PATH_APP.app2.services.root }],
    children: {}
  });
  useEffect(() => {
    let businessIncorporationChildren = {};
    let businessRegistrationChildren = {};

    let gstChildren = {};

    let tdspaymentChildren = {};
    let tdsChildren = {};

    let incomeTaxChildren = {};

    let payrollPreparationChildren = {};
    let registrationChildren = {};
    let paymentandreturnChildren = {};
    let complianceChildren = {};

    let rocFilingsChildren = {};
    let rocmandatoryllpChildren = {};
    let othercompanyfilingsChildren = {};
    let otherllpfilingsChildren = {};

    let accountingChildren = {};
    let compliancesServicesChildren = {};

    let auditChildren = {};
    if (Object.keys(headerData).length !== 0) {
      headerData !== undefined &&
        headerData.businessIncorporationList.data.incorporations.map(item => {
          if (item.checked) {
            businessIncorporationChildren[item.field] =
              BusinessIncorporations.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.businessIncorporationList.data.registrationList.map(item => {
          if (item.checked) {
            businessRegistrationChildren[item.field] =
              BusinessIncorporationsRegistrations.children[item.field];
          }
        });

      headerData !== undefined &&
        headerData.accountingandcompliancesList.data.AccountingServicesUnderPackage.map(
          item => {
            if (item.checked) {
              accountingChildren[item.field] =
                AccountingServices.children[item.field];
            }
          }
        );
      headerData !== undefined &&
        headerData.accountingandcompliancesList.data.ComplianceServicesUnderPackage.map(
          item => {
            if (item.checked) {
              compliancesServicesChildren[item.field] =
                ComplianceServices.children[item.field];
            }
          }
        );

      headerData !== undefined &&
        headerData.PayrollCompliancesList.data.payrollprocessingservice.map(
          item => {
            if (item.checked) {
              payrollPreparationChildren[item.field] =
                payrollprocessingservice.children[item.field];
            }
          }
        );
      headerData !== undefined &&
        headerData.PayrollCompliancesList.data.registration.map(item => {
          if (item.checked) {
            registrationChildren[item.field] =
              Registration.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.PayrollCompliancesList.data.paymentandreturn.map(item => {
          if (item.checked) {
            paymentandreturnChildren[item.field] =
              PaymentAndReturn.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.PayrollCompliancesList.data.compliance.map(item => {
          if (item.checked) {
            complianceChildren[item.field] = Compliance.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.rocFilingsList.data.mandatorycompanyfilings.map(item => {
          if (item.checked) {
            rocFilingsChildren[item.field] =
              MandatoryCompanyFilings.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.rocFilingsList.data.mandatoryllpfilings.map(item => {
          if (item.checked) {
            rocmandatoryllpChildren[item.field] =
              AnnualFilingsLLP.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.rocFilingsList.data.othercompanyfilings.map(item => {
          if (item.checked) {
            othercompanyfilingsChildren[item.field] =
              OtherCompanyFilings.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.rocFilingsList.data.otherllpfilings.map(item => {
          if (item.checked) {
            otherllpfilingsChildren[item.field] =
              OtherLLPFilings.children[item.field];
          }
        });

      headerData !== undefined &&
        headerData.gstList.data.map(item => {
          if (item.checked) {
            gstChildren[item.field] = GSTRegistration.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.tdsList.data.payment.map(item => {
          if (item.checked) {
            tdspaymentChildren[item.field] = PaymentsinTDS.children[item.field];
          }
        });

      headerData !== undefined &&
        headerData.tdsList.data.Returns.map(item => {
          if (item.checked) {
            tdsChildren[item.field] = TDSServices.children[item.field];
          }
        });

      headerData !== undefined &&
        headerData.incometaxFilingList.data.map(item => {
          if (item.checked) {
            incomeTaxChildren[item.field] =
              IncomeTaxReturnsBusiness.children[item.field];
          }
        });
      headerData !== undefined &&
        headerData.auditList.data.map(item => {
          if (item.checked) {
            auditChildren[item.field] = Audit.children[item.field];
          }
        });
      const filteredServices = {
        businessIncorporationChildren,
        businessRegistrationChildren,

        gstChildren,

        tdsChildren,
        tdspaymentChildren,

        incomeTaxChildren,
        payrollPreparationChildren,
        registrationChildren,
        paymentandreturnChildren,
        complianceChildren,
        rocFilingsChildren,
        rocmandatoryllpChildren,
        othercompanyfilingsChildren,
        otherllpfilingsChildren,

        accountingChildren,
        compliancesServicesChildren,

        auditChildren
      };
      let _newServices = { ...services };

      if (
        Object.keys(businessIncorporationChildren).length !== 0 ||
        Object.keys(businessRegistrationChildren).length !== 0
      ) {
        _newServices.children.BusinessIncorporationandRegistrations = {
          title: "Business Incorporation",
          href: PATH_APP.app2.services.BusinessIncorporationandRegistrations,
          icon: "/static2/ServiceIcons/Individual.svg",
          description: "This is Business Incorporation & Registrations",
          heading: "Business Incorporation & Registrations",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "Business Incorporation & Registrations"
            }
          ],

          children: {}
        };
        if (Object.keys(businessIncorporationChildren).length !== 0)
          _newServices.children.BusinessIncorporationandRegistrations.children.BusinessIncorporations = {
            title: "Business Incorporations",
            icon: "/static2/ServiceIcons/Business Incorporations/company.svg",
            href: PATH_APP.app2.services.Company,
            description: "",
            heading: "Business Incorporations",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              { name: "Business Incorporations" }
            ],

            children: filteredServices.businessIncorporationChildren
          };
        if (Object.keys(businessRegistrationChildren).length !== 0)
          _newServices.children.BusinessIncorporationandRegistrations.children.BusinessIncorporationsRegistrations = {
            title: "Registrations",
            icon: "/static2/ServiceIcons/Business Incorporations/company.svg",
            href: PATH_APP.app2.services.OtherGovernmentRegistrations,
            description: "",
            heading: "Other Government Registrations",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              { name: "Other Government Registrations" }
            ],

            children: filteredServices.businessRegistrationChildren
          };
      }
      /// gst
      if (Object.keys(gstChildren).length !== 0)
        _newServices.children.GST = {
          title: "GST",
          href: PATH_APP.app2.services.GSTServices,
          icon: "/static2/ServiceIcons/Individual.svg",
          description: "This is GST",
          heading: "GST",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "GST",
              href: PATH_APP.app2.services.GSTServices
            }
          ],

          children: filteredServices.gstChildren
        };
      // tds
      if (
        Object.keys(tdsChildren).length !== 0 ||
        Object.keys(tdspaymentChildren).length !== 0
      ) {
        _newServices.children.TDSTCS = {
          title: "TDS/TCS",
          href: PATH_APP.app2.services.TDSServices,
          icon: "/static2/ServiceIcons/Business.svg",
          description: "This is TDSTCS",
          heading: "TDSTCS",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "TDSTCS",
              href: PATH_APP.app2.services.TDSServices
            }
          ],
          children: {}
        };
        if (Object.keys(tdspaymentChildren).length !== 0)
          _newServices.children.TDSTCS.children.Payment = {
            title: "TDS /TCS",
            icon: "/static2/ServiceIcons/BusinessServices/GST.svg",
            href: PATH_APP.app2.Payments,
            description: "",
            heading: "Payments",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },
              {
                name: "TDS Services",
                href: PATH_APP.app2.services.TDSServices
              },
              { name: "Payments" }
            ],
            children: { ...filteredServices.tdspaymentChildren }
          };
        if (Object.keys(tdsChildren).length !== 0)
          _newServices.children.TDSTCS.children.TDSServices = {
            title: "Returns",
            icon: "/static2/ServiceIcons/BusinessServices/TDS.svg",
            href: PATH_APP.app2.services.TDSServices,
            description: "",
            heading: "TDS Services",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              { name: "TDS Services" }
            ],
            // children: filteredServices.tdsChildren
            children:
              Object.keys(tdspaymentChildren).length !== 0 &&
              Object.keys(tdsChildren).length !== 0
                ? {
                    ...filteredServices?.tdspaymentChildren,
                    ...filteredServices?.tdsChildren
                  }
                : Object.keys(tdsChildren).length !== 0
                ? filteredServices.tdsChildren
                : {
                    title: "TDS /TCS",
                    icon: "/static2/ServiceIcons/BusinessServices/GST.svg",
                    href: PATH_APP.app2.Payments,
                    description: "",
                    heading: "Payments",
                    breadCrumbs: [
                      { name: "Services", href: PATH_APP.app2.services.root },
                      {
                        name: "TDS Services",
                        href: PATH_APP.app2.services.TDSServices
                      },
                      { name: "Payments" }
                    ],
                    children: { ...filteredServices.tdspaymentChildren }
                  }
          };
      }

      // incometaxfiling
      if (Object.keys(incomeTaxChildren).length !== 0) {
        _newServices.children.IncomeTaxFilings = {
          title: "Income Tax Filings",
          href: PATH_APP.app2.services.IncomeTaxReturnsBusiness,
          icon: "/static2/ServiceIcons/Business.svg",
          description: "This is Income Tax Filings",
          heading: "Income Tax Filings",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "Income Tax Filings",
              href: PATH_APP.app2.services.IncomeTaxReturns
            }
          ],
          children: filteredServices.incomeTaxChildren
        };
      }
      // payroll
      if (
        Object.keys(payrollPreparationChildren).length !== 0 ||
        Object.keys(registrationChildren).length !== 0 ||
        Object.keys(paymentandreturnChildren).length !== 0 ||
        Object.keys(complianceChildren).length !== 0
      ) {
        _newServices.children.Payrollcompliances = {
          title: "Payroll compliances",
          href: PATH_APP.app2.services.PayrollCompliances,
          icon: "/static2/ServiceIcons/Business.svg",
          description: "This is Payroll compliances",
          heading: "Payroll compliances",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "Payroll compliances",
              href: PATH_APP.app2.services.PayrollCompliances
            }
          ],
          children: {}
        };
        if (Object.keys(payrollPreparationChildren).length !== 0) {
          _newServices.children.Payrollcompliances.children.payrollprocessingservice = {
            title: "Payroll Preparation",
            icon: "/static2/ServiceIcons/BusinessServices/Payroll.svg",
            href: PATH_APP.app2.services.PayrollPreparation,
            description: "",
            heading: "Payroll",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },
              {
                name: "Payroll Compliances",
                href: PATH_APP.app2.services.PayrollCompliances
              },
              { name: "Payroll" }
            ],
            children: filteredServices.payrollPreparationChildren
          };
        }
        if (Object.keys(registrationChildren).length !== 0) {
          _newServices.children.Payrollcompliances.children.Registration = {
            title: "Registration",
            icon: "/static2/ServiceIcons/PayrollServices/ESI.svg",
            href: PATH_APP.app2.services.Registration,
            description: "",
            heading: "Registration",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "Payroll Compliances",
                href: PATH_APP.app2.services.PayrollCompliances
              },
              { name: "ESI" }
            ],
            children: filteredServices.registrationChildren
          };
        }
        if (Object.keys(paymentandreturnChildren).length !== 0) {
          _newServices.children.Payrollcompliances.children.PaymentAndReturn = {
            title: "Payment And Return",
            icon: "/static2/ServiceIcons/PayrollServices/ESI.svg",
            href: PATH_APP.app2.PayrollCompliances.PaymentAndReturn,
            description: "",
            heading: "Payment and Return",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "Payroll Compliances",
                href: PATH_APP.app2.services.PayrollCompliances
              },
              { name: "Payment and Return" }
            ],
            children: filteredServices.paymentandreturnChildren
          };
        }
        if (Object.keys(complianceChildren).length !== 0) {
          _newServices.children.Payrollcompliances.children.Compliance = {
            title: "Compliance ",
            icon: "/static2/ServiceIcons/PayrollServices/ESI.svg",
            href: PATH_APP.app2.services.ESI,
            description: "",
            heading: "Compliance ",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "Payroll Compliances",
                href: PATH_APP.app2.services.PayrollCompliances
              },
              { name: "ESI" }
            ],

            children: filteredServices.complianceChildren
          };
        }
      }

      //roc
      if (
        Object.keys(rocFilingsChildren).length !== 0 ||
        Object.keys(rocmandatoryllpChildren).length !== 0 ||
        Object.keys(othercompanyfilingsChildren).length !== 0 ||
        Object.keys(otherllpfilingsChildren).length !== 0
      ) {
        _newServices.children.ROCFilings = {
          title: "ROC Filings",
          href: PATH_APP.app2.services.ROCCompliances,
          icon: "/static2/ServiceIcons/Business.svg",
          description: "This is ROC Filings",
          heading: "ROC Filings",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "ROC Filings",
              href: PATH_APP.app2.services.ROCCompliances
            }
          ],
          children: {}
        };
        if (Object.keys(rocFilingsChildren).length !== 0) {
          _newServices.children.ROCFilings.children.MandatoryCompanyFilings = {
            title: "Annual Filings",
            icon: "/static2/ServiceIcons/ROCServices/Annual filings.svg",
            href: PATH_APP.app2.services.AnnualFilingsCompany,
            description: "",
            heading: "Annual Filings",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "ROC Compliances",
                href: PATH_APP.app2.services.ROCCompliances
              },
              {
                name: "Company Filings",
                href: PATH_APP.app2.services.CompanyFilings
              },
              { name: "Annual Filings" }
            ],
            children: filteredServices.rocFilingsChildren
          };
        }
        if (Object.keys(rocmandatoryllpChildren).length !== 0) {
          _newServices.children.ROCFilings.children.MandatoryLLPFilingsFilings = {
            title: "Mandatory LLP Filings",
            icon: "/static2/ServiceIcons/ROCServices/Annual filings.svg",
            href: PATH_APP.app2.services.AnnualFilingsLLP,
            description: "",
            heading: "Annual Filings",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "ROC Compliances",
                href: PATH_APP.app2.services.ROCCompliances
              },
              { name: "LLP Filings", href: PATH_APP.app2.services.LLPFilings },
              { name: "Annual Filings" }
            ],
            children: filteredServices.rocmandatoryllpChildren
          };
        }
        if (Object.keys(othercompanyfilingsChildren).length !== 0) {
          _newServices.children.ROCFilings.children.OtherCompanyFilings = {
            title: "Other Company Filings",
            icon: "/static2/ServiceIcons/ROCServices/Company Roc Filings.svg",
            href: PATH_APP.app2.services.CompanyFilings,
            description: "",
            heading: "Company Filings",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "ROC Compliances",
                href: PATH_APP.app2.services.ROCCompliances
              },
              { name: "Company Filings" }
            ],
            children: filteredServices.othercompanyfilingsChildren
          };
        }
        if (Object.keys(otherllpfilingsChildren).length !== 0) {
          _newServices.children.ROCFilings.children.OtherLLPFilings = {
            title: "Other LLP Filings Filings",
            icon: "/static2/ServiceIcons/ROCServices/Company Roc Filings.svg",
            href: PATH_APP.app2.services.LLPFilings,
            description: "",
            heading: "Other LLP Filings",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },

              {
                name: "ROC Compliances",
                href: PATH_APP.app2.services.ROCCompliances
              },
              { name: "LLP Filings", href: PATH_APP.app2.services.LLPFilings },
              { name: "Other LLP Filings" }
            ],
            children: filteredServices.otherllpfilingsChildren
          };
        }
      }
      //accounting
      if (
        Object.keys(accountingChildren).length !== 0 ||
        Object.keys(compliancesServicesChildren).length !== 0
      ) {
        _newServices.children.AccountingAndCompliances = {
          title: "Accounting",
          href: PATH_APP.app2.services.AccountingAndBookkeeping,
          icon: "/static2/ServiceIcons/Business.svg",
          description: "This is Accounting & Compliances",
          heading: "Accounting & Compliances",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "Accounting & Compliances",
              href: PATH_APP.app2.services.AccountingAndBookkeeping
            }
          ],
          children: {}
        };
        if (Object.keys(accountingChildren).length !== 0) {
          _newServices.children.AccountingAndCompliances.children.AccountingServices = {
            title: "Accounting Services Under package",
            icon:
              "/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg",
            href: PATH_APP.app2.services.AccountingServicesUnderPackage,
            description: "",
            heading: "Accounting Services Under package",
            breadCrumbs: [
              { name: "Services", href: PATH_APP.app2.services.root },
              {
                name: "Accounting & Compliances",
                href: PATH_APP.app2.services.AccountingAndBookkeeping
              },
              { name: "Accounting Services Under Package" }
            ],
            children: filteredServices.accountingChildren
          };
        }
        if (Object.keys(compliancesServicesChildren).length !== 0) {
          _newServices.children.AccountingAndCompliances.children.ComplinaceServices = ComplianceServices;
        }
      }
      // complince services

      //audit
      if (Object.keys(auditChildren).length !== 0) {
        _newServices.children.Audit = {
          title: "Audit",
          href: PATH_APP.app2.services.Audit,
          icon: "/static2/ServiceIcons/Business.svg",
          description: "This is Audit",
          heading: "Audit",
          breadCrumbs: [
            { name: "Services", href: PATH_APP.app2.services.root },
            {
              name: "Audit",
              href: PATH_APP.app2.services.Audit
            }
          ],
          children: filteredServices.auditChildren
        };
      }
      setServices({ ..._newServices });
      dispatch(
        getlandingpageData({
          resObj: { ...res?.data },
          serviceConstants: { ..._newServices }
        })
      );
      setLoading(false);
    }
  }, [headerData]);

  return <></>;
}

export default Servicesloader;
